define('motorplanit/routes/users/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    permissions: Ember.inject.service(),
    queryParams: {
      groupId: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      if (!this.get('permissions').canViewUsers()) {
        return this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      return this.store.findAll('user').then(function (users) {
        if (params && params.groupId) {
          return users.reject(function (user) {
            return user.get('applicationRole.id') !== params.groupId;
          });
        }
        return users;
      });
    }
  });
});