define('motorplanit/helpers/load-images', ['exports', 'motorplanit/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loadImages = loadImages;


  // Get call for files/exporttool
  function loadImages() {
    Ember.$.ajax({
      url: _environment.default.apiUrl + '/files/exporttool',
      xhrFields: { withCredentials: true },
      type: 'GET',
      contentType: 'application/json;charset=utf-8',
      dataType: 'json'
    }).then(function (response) {
      var images = response['item'];
      if (Ember.isArray(images)) {
        images.forEach(function (img) {
          img['src'] = img['item2'];
          img['w'] = 1454;
          img['h'] = 775;
          img['title'] = '<button type="button" data-img-id="' + img['item1'] + '" data-img-url="' + img['src'] + '" class="button button-rounded button-bordered img-button btn-img-close">Select Image</button>';
        });
      }
      return images;
    });
  }

  exports.default = Ember.Helper.helper(loadImages);
});