define('motorplanit/models/clinic-role', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: (0, _attr.default)('string'),
    isAdmin: (0, _attr.default)('boolean'),
    isTherapist: (0, _attr.default)('boolean'),
    isGuardian: (0, _attr.default)('boolean'),
    isPatient: (0, _attr.default)('boolean')
  });
});