define('motorplanit/validations/guardian', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    relationshipId: (0, _validators.validatePresence)(true),
    firstName: (0, _validators.validatePresence)(true),
    lastName: (0, _validators.validatePresence)(true)
  };
});