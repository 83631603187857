define('motorplanit/routes/activities/edit', ['exports', 'motorplanit/mixins/activities-routes-common', 'motorplanit/mixins/authenticated-route'], function (exports, _activitiesRoutesCommon, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRoute.default, _activitiesRoutesCommon.default, {
    permissions: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get('permissions').isSuperAdmin()) {
        return this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        activity: this._findActivityById(params.activity_id),
        activityTypes: this.get('store').findAll('activityType'),
        activityLevelTypes: this.getActivityLevelTypes(),
        fileTypes: this.get('store').findAll('fileType'),
        sensorySystems: this.getSensorySystems()
      });
    },

    setupController: function setupController(controller, model) {
      this._setModel(controller, model);
    }
  });
});