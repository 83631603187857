define('motorplanit/components/contact-form', ['exports', 'motorplanit/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.changesetContact = get(this, 'contact');
      this.dataFieldLabel = 'Data';
      var contactType = get(this.changesetContact, 'contactType');

      this.showDataField = get(contactType, 'isEmail') || get(contactType, 'isPhoneNumber');
      this.showAdressFields = get(contactType, 'isAddress');
      if (get(contactType, 'isEmail')) this.dataFieldLabel = "Email";
      if (get(contactType, 'isPhoneNumber')) this.dataFieldLabel = "Phone Number";
    },
    didInsertElement: function didInsertElement() {
      $('.panel-contacts .tabs-column li, .panel-contacts .tab-pane').removeClass('active in');
      $('.panel-contacts .tabs-column li:last, .panel-contacts .tab-pane:last-child').addClass('active in');
    },

    actions: {
      deleteContact: function deleteContact(changesetContact) {
        var confirmed = window.confirm('Are you sure you want to delete?');
        if (confirmed) {
          var parentUrl = get(this.changeset, 'parentUrl');

          var contact = get(this.model, 'contacts').findBy('id', get(changesetContact, 'id'));
          var contactId = get(changesetContact, 'id');
          var userId = get(contact, 'user.id');

          if (parentUrl) {
            contact.set('parentUrl', parentUrl);
            contact.destroyRecord();
          } else if (userId) {
            //This is for clinic location contacts
            Ember.$.ajax({
              url: _environment.default.apiUrl + '/users/' + userId + '/contacts/' + contactId,
              xhrFields: { withCredentials: true },
              type: 'DELETE',
              contentType: 'application/json;charset=utf-8',
              dataType: 'json'
            });
            contact.unloadRecord();
          } else {
            var clinicId = get(this.changeset, 'clinic.id');
            var locationId = get(this.changeset, 'id');
            //This is for clinic location contacts
            Ember.$.ajax({
              url: _environment.default.apiUrl + '/clinics/' + clinicId + '/locations/' + locationId + '/contacts/' + contactId,
              xhrFields: { withCredentials: true },
              type: 'DELETE',
              contentType: 'application/json;charset=utf-8',
              dataType: 'json'
            });
            contact.unloadRecord();
          }

          this.get('changeset._contacts').removeObject(changesetContact);
        }
      }
    }
  });
});