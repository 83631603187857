define('motorplanit/serializers/clinic', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      contacts: { embedded: 'always' },
      locations: { embedded: 'always' },
      admins: { embedded: 'always' },
      devices: { embedded: 'always' }
    },
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      payload = {
        clinics: payload.items
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      payload.item.contacts = this._normalizeContacts(payload.item.contacts);
      payload.item.locations = this._normalizeLocationContacts(payload.item.locations);
      payload.item.devices = this._normalizeDevices(payload.item.devices);

      payload = {
        clinic: payload.item
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      Ember.merge(hash, this.serialize(record, options));
    },
    normalizeSaveResponse: function normalizeSaveResponse(store, primaryModelClass, payload, id, requestType) {
      payload.item.contacts = this._normalizeContacts(payload.item.contacts);
      payload.item.locations = this._normalizeLocationContacts(payload.item.locations);
      payload.item.devices = this._normalizeDevices(payload.item.devices);

      payload = {
        clinic: payload.item
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    _normalizeLocationContacts: function _normalizeLocationContacts(locations) {
      var _this = this;

      locations = locations.map(function (location) {
        location.contacts = _this._normalizeContacts(location.contacts);
        return location;
      });
      return locations;
    },

    // Need to change attributes from API like 'type', 'data' reserved words.
    _normalizeContacts: function _normalizeContacts(contacts) {
      var newContacts = Ember.A();
      newContacts = contacts.map(function (contact) {
        contact._data = contact.data;
        contact.contactType = contact.type;
        delete contact['data'];
        delete contact['type'];
        return contact;
      });
      return newContacts;
    },
    _normalizeDevices: function _normalizeDevices(devices) {
      var newDevices = Ember.A();
      newDevices = devices.map(function (device) {
        device.clinic = device.clinicId;
        device.clinicLocation = device.clinicLocationId;
        return device;
      });
      return newDevices;
    }
  });
});