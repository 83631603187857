define('motorplanit/models/contact', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    typeId: (0, _attr.default)('string'),
    _data: (0, _attr.default)('string'),
    addressLine1: (0, _attr.default)('string'),
    addressLine2: (0, _attr.default)('string'),
    addressPostcode: (0, _attr.default)('string'),
    addressCountryId: (0, _attr.default)('string'),
    addressCountryState: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    addressCity: (0, _attr.default)('string'),
    addressCountryCity: (0, _attr.default)('string'),
    contactType: _emberData.default.belongsTo('contact-type'),
    addressCountry: (0, _attr.default)(),
    location: _emberData.default.belongsTo('location'),
    clinic: _emberData.default.belongsTo('clinic'),
    user: _emberData.default.belongsTo('user')
  });
});