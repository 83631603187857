define('motorplanit/validations/level-step', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    // image: validatePresence(true),
    order: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ integer: true })]
  };
});