define('motorplanit/components/user-relationships', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    permissions: Ember.inject.service(),
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.relation;
    },

    actions: {
      showDeleteModal: function showDeleteModal(user, relation) {
        this.relation = relation;
      },
      deleteModel: function deleteModel() {
        this.relation.set('parentUserId', this.model.get('id'));
        this.relation.destroyRecord();
        $('#' + this.relation.id).remove();
      }
    }
  });
});