define('motorplanit/controllers/users/new', ['exports', 'motorplanit/mixins/users-controller'], function (exports, _usersController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_usersController.default, {
    actions: {
      save: function save(changeset) {
        this.createPhotoAndSaveUser(changeset);
      }
    }
  });
});