define('motorplanit/models/contact-type', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: (0, _attr.default)('string'),
    isEmail: (0, _attr.default)('boolean'),
    isAddress: (0, _attr.default)('boolean'),
    isPhoneNumber: (0, _attr.default)('boolean')
  });
});