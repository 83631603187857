define('motorplanit/routes/clinics/show', ['exports', 'motorplanit/mixins/clinics-routes-common', 'motorplanit/mixins/authenticated-route'], function (exports, _clinicsRoutesCommon, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRoute.default, _clinicsRoutesCommon.default, {
    permissions: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get('permissions').canViewClinics()) {
        return this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      var perms = this.get('permissions');
      if (perms.isClinicAdmin() && !perms.isClinicRelated(params.clinic_id)) {
        return this.transitionTo('forbidden');
      }

      return this.get('store').find('clinic', params.clinic_id);
    }
  });
});