define('motorplanit/adapters/application', ['exports', 'ember-data', 'motorplanit/config/environment'], function (exports, _emberData, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.RESTAdapter.extend({
        host: _environment.default.apiUrl,
        ajaxOptions: function ajaxOptions(url, type, options) {
            var hash = this._super(url, type, options);
            hash.dataType = "json";
            hash.xhrFields = { withCredentials: true };
            hash.contentType = 'application/json;charset=utf-8';

            if (type === 'DELETE') hash.dataType = 'text';

            return hash;
        }

    });
});