define('motorplanit/adapters/device', ['exports', 'motorplanit/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    generatedDetailedMessage: function generatedDetailedMessage(status, headers, payload) {
      return payload.error.message;
    }
  });
});