define('motorplanit/mixins/users-routes-common', ['exports', 'motorplanit/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    permissions: Ember.inject.service(),
    genders: function genders() {
      return Ember.$.ajax({
        url: _environment.default.apiUrl + '/genders',
        xhrFields: { withCredentials: true },
        type: 'GET',
        contentType: 'application/json;charset=utf-8',
        dataType: 'json'
      }).then(function (response) {
        return response.items;
      });
    },
    roles: function roles() {
      var _this = this;
      return Ember.$.ajax({
        url: _environment.default.apiUrl + '/applicationRoles',
        xhrFields: { withCredentials: true },
        type: 'GET',
        contentType: 'application/json;charset=utf-8',
        dataType: 'json'
      }).then(function (response) {
        if (_this.get('permissions').isSuperAdmin()) {
          return response.items.filter(function (item) {
            return ['Device', 'Root'].includes(item.name) === false;
          });
        }
        if (_this.get('permissions').isClinicAdmin()) {
          return response.items.filter(function (item) {
            return ['Clinic Admin', 'Therapist', 'Guardian', 'Patient'].includes(item.name);
          });
        }
        if (_this.get('permissions').isTherapist()) {
          return response.items.filter(function (item) {
            return ['Guardian', 'Patient'].includes(item.name);
          });
        }
        return [];
      });
    },
    setModel: function setModel(controller, model) {
      controller.set('model', model.user);
      controller.set('genders', model.genders);
      controller.set('contactTypes', model.contactTypes);
      controller.set('guardianRelationships', model.guardianRelationships);
      controller.set('fileType', model.fileTypes.findBy('name', 'Image'));
      controller.set('roles', model.roles);
      controller.set('clinics', model.clinics);
      controller.set('clinicRoles', model.clinicRoles);
    }
  });
});