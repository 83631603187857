define('motorplanit/models/level', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    typeId: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    allowedAttemptsCount: (0, _attr.default)('number'),
    requiredSuccessfulAttempts: (0, _attr.default)('number'),
    activity: _emberData.default.belongsTo('activity'),
    steps: _emberData.default.hasMany('level-step')
  });
});