define('motorplanit/controllers/users/relations/edit', ['exports', 'motorplanit/mixins/relationships-controller-common'], function (exports, _relationshipsControllerCommon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_relationshipsControllerCommon.default, {
    actions: {
      save: function save(changeset, userId) {
        var _this = this;
        changeset.validate();
        if (!changeset.get('isValid')) return;
        if (changeset.get('relationship.isOther') && !changeset.get('otherRelationship')) {
          changeset.addError('otherRelationship', 'Must Specify Relationship Type');
          return;
        }
        return changeset.save().then(function () {
          _this.transitionToRoute('users.show', userId);
        });
      }
    }
  });
});