define("motorplanit/components/edit-activity-form", ["exports", "motorplanit/validations/activity", "motorplanit/config/environment", "ember-changeset-validations", "ember-changeset", "motorplanit/helpers/load-images", "fuse"], function (exports, _activity, _environment, _emberChangesetValidations, _emberChangeset, _loadImages, _fuse) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get;
  exports.default = Component.extend({
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);
      var model = get(this, "model");
      var hasExtendedDetails = get(this, "hasExtendedDetails");
      this.changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_activity.default), _activity.default);
      this.changeset.set("hasExtendedDetails", hasExtendedDetails);
      this.nestedLevels = [];
      this.sensometers = [25, 50, 75, 100];
      this.activityLevels = [];
      this.imagesLoaded = false;
      this.galleryInitiated = false;
      this.images = [];
      // this.imageIds = [];
      this.psOpts = {
        index: 1,
        hideShare: true,
        hideToggleFullScreen: true,
        history: false,
        mouseUsed: true
      };

      // setup images to be used by Photoswipe
      if (Ember.isArray(this.images)) {
        this.images.forEach(function (img) {
          img["src"] = img["item1"];
          img["w"] = 1454;
          img["h"] = 775;
          img["title"] = "<button type=\"button\" data-img-id=\"" + img["item2"] + "\" data-img-url=\"" + img["src"] + "\" class=\"button button-rounded button-bordered img-button btn-img-close\">Select Image</button>";
        });
      }

      if (model.id) {
        this.changeset.set("typeId", model.type.id);
        if (this.model.avatarFile) {
          this.changeset.set("avatarFileId", this.model.avatarFile.id);
        }
      }

      //Find levels for activity object already created.
      if (Ember.isArray(model.levels)) {
        model.levels.forEach(function (modelLevel) {
          var level = _this2.activityLevelTypes.findBy("id", modelLevel.type.id);
          _this2.activityLevels.pushObject(level);
          _this2.activityLevelTypes.removeObject(level);
        });
      }

      if (Ember.isArray(model.sensorySystems)) {
        var sensorySystemsArray = model.sensorySystems.mapBy("sensorySystem.id");

        //This is for multiselect options already selected
        this.sensorySelected = this.sensorySystems.filter(function (item) {
          return sensorySystemsArray.includes(item.id);
        });
      }
    },
    didRender: function didRender() {
      var _this3 = this;

      this._super.apply(this, arguments);
      // Load Images after Page Load
      if (!this.imagesLoaded) {
        Ember.$.ajax({
          url: _environment.default.apiUrl + "/files/exporttoolv2",
          xhrFields: { withCredentials: true },
          type: "GET",
          contentType: "application/json;charset=utf-8",
          dataType: "json"
        }).then(function (response) {
          var images = response["items"];
          var imageUrls = [];
          if (Ember.isArray(images)) {
            images.forEach(function (img) {
              imageUrls.pushObject(img["url"]);
              img["w"] = 1454;
              img["h"] = 775;
            });
          }

          // Load tmp image urls for each image
          Ember.$.ajax({
            url: _environment.default.apiUrl + "/files/downloadurls",
            xhrFields: { withCredentials: true },
            type: "POST",
            contentType: "application/json;charset=utf-8",
            dataType: "json",
            data: JSON.stringify({ urls: imageUrls })
          }).then(function (response) {
            var downloadUrls = response.items;
            var index = 0;
            images.forEach(function (img) {
              img["src"] = downloadUrls[index]["downloadUrl"];
              img["title"] = "<button type=\"button\" data-img-id=\"" + img["id"] + "\" data-img-url=\"" + img["src"] + "\" class=\"button button-rounded button-bordered img-button btn-img-close\">Select Image</button>";
              index = index + 1;
            });
            _this3.set("images", images);
            _this3.set("imagesLoaded", true);
            return false;
          });
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce("afterRender", this, this.afterRenderEvent);
      var _this = this;
      var initialImage = null;

      $(".step-image").fileinput({
        showRemove: false,
        showUpload: false,
        showCaption: false,
        browseLabel: "UPLOAD IMAGE"
      });

      $(".step-video").fileinput({
        showRemove: false,
        showUpload: false,
        initialCaption: "Upload video",
        browseLabel: "ADD VIDEO"
      });

      if (this.model.avatarFile) {
        initialImage = "<img src='" + this.model.avatarFile.url + "' class='file-preview-image'>";
        // $('#img-preview').html(initialImage)
      }

      $(".upload-image").fileinput({
        initialPreview: [initialImage],
        showRemove: false,
        showUpload: false,
        showCaption: false,
        browseLabel: "UPLOAD IMAGE"
      });

      // $('.step-image, .upload-image').on('fileclear', function() {
      //   $(this).trigger("change");
      // });

      $(".select-foundation").select2({
        placeholder: "Select Foundation",
        minimumResultsForSearch: Infinity
      });

      $(".sensory-systems").select2({
        placeholder: "Sensory Systems",
        minimumResultsForSearch: Infinity
      });

      $("#level-type-selector").select2({
        placeholder: "Select Level Type",
        minimumResultsForSearch: Infinity
      });

      $(".bootstrap-touchspin-up, .bootstrap-touchspin-down").click(function () {
        var input = $(this).closest(".bootstrap-touchspin").find(".input-amount");
        input.trigger("input");
      });

      //Hide/ Show controls accoring to the ActivityType(Foundation) option selected.
      $("#foundation-selector").change(function () {
        var activityTypeId = $(this).val();
        var activityType = _this.activityTypes.find(function (item) {
          return item.id == activityTypeId;
        });

        _this.customizeActivityForm(activityType);
      });

      $("#isHome").change(function () {
        var isHome = !$(this).prop("checked");
        var activityTypeId = $('#foundation-selector').val();
        var activityType = _this.activityTypes.find(function (activityType) {
          return activityType.id === activityTypeId;
        });
        if (!activityType || isHome && activityType && activityType.get('isSensory') || activityType && activityType.get('hasExtendedDetails')) {
          $("#extended-details, #sensometer-options").show();
          $("#column-1").attr("class", "col-sm-6");
        } else {
          $("#extended-details, #sensometer-options").hide();
          $("#extended-details textarea").val('');
          $("#column-1").attr("class", "col-sm-11");
        }
      });
    },
    afterRenderEvent: function afterRenderEvent() {
      var model = get(this, "model");
      var activityTypeId = get(this.changeset, "typeId");

      // avatar image handlers
      // selet Image button
      $("body").on("click", ".img-button", function () {
        var $imgPreview = $(this).parents(".avatar-image").find(".file-preview");
        var $avatarInput = $(this).parents(".avatar-image").find(".img-input");

        var imgId = $(this).data("img-id");
        var imgUrl = $(this).data("img-url");

        $imgPreview.show();
        $imgPreview.find(".close").show();

        $imgPreview.find(".file-preview-thumbnails").html("\n          <div class=\"file-preview-frame\" data-fileindex=\"0\">\n            <img src=\"" + imgUrl + "\" class=\"file-preview-image\">\n          </div>");
        $avatarInput.val(imgId);
        $avatarInput.change();
        return false;
      });

      $("body").on("change", ".upload-image", function () {
        var $image = $(this).parents(".avatar-image");
        alert(image);
        $image.find(".close").show();
        $image.find(".file-preview").show();
      });

      $("body").on("click", ".close", function () {
        $(this).parent().hide();
      });

      $("body").on("click", ".btn-img-close", function () {
        $(this).parents(".avatar-image").find(".pswp").hide();
        $(".preview-avatar").hide();
      });

      $("body").on("click", ".pswp__button", function (e) {
        e.preventDefault();
      });

      var activityType = this.activityTypes.find(function (item) {
        return item.id == activityTypeId;
      });

      // this is for checkbox styling to match multiselect-checkbox styling
      $(".content").on("click", ".form-checkbox", function () {
        var $checkbox = $(this).find("input");
        $checkbox.trigger("change");
      });

      if (!Ember.isArray(model.levels) || model.levels.length === 0) $(".panel-levels").hide();
      if (activityType) this.customizeActivityForm(activityType);
    },
    customizeActivityForm: function customizeActivityForm(activityType) {
      //Hide/show color picker
      $("#color-picker").css("display", get(activityType, "hasColor") ? "block" : "none");

      //Hide/show avatarImage
      var isAvatarUsed = get(activityType, "isAvatarUsed");
      $("#avatar-image").css("display", isAvatarUsed ? "block" : "none");
      $("#img-preview").css("display", isAvatarUsed ? "block" : "none");

      //Hide/show extended details textareas
      if (get(activityType, "hasExtendedDetails")) {
        $("#extended-details, #sensometer-options").show();
        $("#column-1").attr("class", "col-sm-6");
      } else if (get(activityType, "isSensory") && this.changeset.get('isHome')) {
        $("#extended-details, #sensometer-options").show();
        $("#column-1").attr("class", "col-sm-6");
      } else {
        $("#extended-details, #sensometer-options").hide();
        $("#column-1").attr("class", "col-sm-11");
      }
    },

    actions: {
      initGallery: function initGallery(item) {
        $(".btn-img-open").parents(".avatar-image").find(".pswp").show();
        this.get("myGallery").init();
        this.get("myGallery").goTo(item);
      },
      duplicate: function duplicate() {
        var activityId = get(this.changeset, 'id');
        var _this = this;
        Ember.$.ajax({
          url: _environment.default.apiUrl + "/activities/copy/" + activityId,
          xhrFields: { withCredentials: true },
          type: 'POST',
          contentType: false,
          processData: false
        }).then(function (response) {
          alert("Activity copied successfully");
          var router = _this.get('router');
          router.transitionTo('activities.edit', response.item);
        });
      },
      handleImageChange: function handleImageChange(imageId) {
        this.changeset.set("avatarFileId", imageId);
        this.changeset.set("avatar", null);
      },
      handleAvatar: function handleAvatar(event) {
        this.changeset.set("avatar", event.target.files[0]);
      },
      colorChanged: function colorChanged(color) {
        this.changeset.set("color", color);
      },

      updateSelection: function updateSelection(newSelection) {
        this.changeset.set("newSensorySystems", newSelection);
      },
      addLevel: function addLevel() {
        $(".tab-pane").removeClass("active in");
        $("#tab-zero").addClass("active in");
        $(".panel-levels").show();
        $("#level-type-selector").select2({
          placeholder: "Select Level Type",
          allowClear: true,
          minimumResultsForSearch: Infinity
        });
      },
      changedIsHome: function changedIsHome(value) {
        this.changeset.set("isHome", !this.changeset.get("isHome"));
        this.changeset.validate();
      },
      changedIsEnterprise: function changedIsEnterprise(value) {
        this.changeset.set("isEnterprise", !this.changeset.get("isEnterprise"));
      },
      openAvatarPreview: function openAvatarPreview() {
        $(".preview-avatar").show();
        window.scroll(0, 0);
        $(".form-control.preview-input").val("");
        this.actions.searchPreviewItem("");
      },
      closeAvatarPreview: function closeAvatarPreview() {
        $(".preview-avatar").hide();
      },
      searchPreviewItem: function searchPreviewItem(search) {
        if (search === "") {
          $(".preview-element").show();
          return;
        }

        $(".preview-element").hide();
        var options = {
          includeMatches: true,
          keys: ["word"],
          shouldSort: true,
          threshold: 0.1
        };
        var searchArr = $(".preview-element").toArray().map(function (x) {
          return { word: $(x).data("name").toLowerCase(), item: x };
        });
        var fuse = new _fuse.default(searchArr, options);
        var result = fuse.search(search);
        result.forEach(function (x) {
          return $(x.item.item).show();
        });
      }
    }
  });
});