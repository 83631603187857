define('motorplanit/components/relationship-form', ['exports', 'ember-changeset', 'ember-changeset-validations', 'motorplanit/validations/relation'], function (exports, _emberChangeset, _emberChangesetValidations, _relation) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var model = get(this, 'model');
      this.changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_relation.default), _relation.default);
      this.changeset.set('parentUserId', this.user.id);

      if (this.get('model.relationship')) {
        this.changeset.set('relationshipId', this.get('model.relationship.id'));
      }
      if (this.get('model.user')) {
        this.changeset.set('userId', this.get('model.user.id'));
      }
    },


    showExplain: Ember.computed('changeset.relationshipId', function () {
      var id = this.get('changeset.relationshipId');

      var show = this.get('guardianRelationships').filter(function (item) {
        return item.get('isOther') && item.get('id') === id;
      }).length > 0;

      if (show === false) {
        this.set('changeset.otherRelationship', null);
      }

      return show;
    }),

    didInsertElement: function didInsertElement() {
      $('.select-relationship').select2({
        placeholder: 'Relationship Type',
        minimumResultsForSearch: Infinity
      });

      $('.select-user').select2({
        placeholder: 'User'
      });
    },


    actions: {
      handleRelationshipChange: function handleRelationshipChange(value) {
        this.changeset.set('relationship', this.get('guardianRelationships').findBy('id', value));
        this.changeset.set('relationshipId', value);
      },
      handleUserChange: function handleUserChange(value) {
        this.changeset.set('userId', value);
      }
    }

  });
});