define('motorplanit/validations/device', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    userName: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({ type: 'email' })],
    clinicLocationId: (0, _validators.validatePresence)(true),
    password: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 8 })]
  };
});