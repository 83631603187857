define('motorplanit/router', ['exports', 'motorplanit/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('groups');
    this.route('users', function () {
      this.route('new');
      this.route('edit', { path: ':user_id/edit' });
      this.route('show', { path: '/:user_id' });
      this.route('profile');
      this.route('relations', { path: ':user_id/relations' }, function () {
        this.route('new');
        this.route('edit', { path: ':relation_id/edit' });
      });
    });
    this.route('clinics', function () {
      this.route('new');
      this.route('show', { path: '/:clinic_id' });
      this.route('edit', { path: ':clinic_id/edit' });
      this.route('locations', { path: ':clinic_id/locations' }, function () {
        this.route('new');
        this.route('edit', { path: ':location_id/edit' });
      });
    });
    this.route('activities', function () {
      this.route('new');
      this.route('activity', { path: 'activity/:activity_id' });
      this.route('edit', { path: 'edit/:activity_id' });
    });
    this.route('glossary', function () {
      this.route('new');
      this.route('edit', { path: ':glossary_term_id/edit' });
      this.route('show', { path: '/:glossary_term_id' });
    });
    this.route('login');
    this.route('forgot_password');
    this.route('change_password');
    this.route('not-found', { path: '/*path' });
    this.route('forbidden');
    this.route('devices', function () {
      this.route('new');
      this.route('edit', { path: ':device_id/edit' });
      this.route('show', { path: '/:device_id' });
    });
    this.route('email_sent');
  });

  exports.default = Router;
});