define('motorplanit/validations/level', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    typeId: (0, _validators.validatePresence)(true),
    allowedAttemptsCount: (0, _validators.validateNumber)({ integer: true, gt: 0 }),
    requiredSuccessfulAttempts: (0, _validators.validateNumber)({ integer: true, gt: 0 })
  };
});