define('motorplanit/validators/contact-data-empty-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateContactDataEmptyField;
  var get = Ember.get;
  function validateContactDataEmptyField() {
    return function (key, newValue, oldValue, changes) {
      var value = newValue || oldValue;
      if (typeof get('changes', 'contactType') === 'undefined') return true;
      if (get(changes, 'contactType.isAddress')) return true;
      var isInvalid = typeof value == 'undefined' || value == null;
      var fieldName = get(changes, 'contactType.isEmail') ? 'Email' : 'Phone Number';
      return !isInvalid || fieldName + ' can\'t be blank.';
    };
  }
});