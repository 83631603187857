define('motorplanit/mixins/users-controller', ['exports', 'motorplanit/validations/user', 'motorplanit/mixins/contacts-controller', 'motorplanit/mixins/global-error-handler', 'motorplanit/config/environment'], function (exports, _user, _contactsController, _globalErrorHandler, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Mixin.create(_contactsController.default, _globalErrorHandler.default, {
    UserValidations: _user.default,
    createPhotoAndSaveUser: function createPhotoAndSaveUser(changeset) {
      var _this = this;
      var isInvalid = this.validateAllChangesets(changeset);
      if (isInvalid) return;

      return changeset.validate().then(function () {
        if (get(changeset, 'isValid')) {
          if (get(changeset, 'photo')) {
            var formData = new FormData();
            var typeId = _this.fileType.id;
            var dataFile = JSON.stringify({ typeId: typeId });
            formData.append('file', changeset._changes.photo);
            formData.append('data', dataFile);

            Ember.$.ajax({
              url: _environment.default.apiUrl + '/files',
              data: formData,
              xhrFields: { withCredentials: true },
              type: 'POST',
              contentType: false,
              processData: false
            }).then(function (response) {
              changeset.set('photoFileId', response.item.id);
              _this.saveUser(changeset, _this);
            });
          } else {
            if (get(changeset, 'photoFile')) {
              changeset.set('photoFileId', get(changeset, 'photoFile.id'));
            }
            _this.saveUser(changeset, _this);
          }
        }
      });
    },
    saveUser: function saveUser(changeset, _this) {
      return changeset.save().then(function (response) {
        var parentUrl = 'users/' + response.id;
        var promises = _this.saveContacts(parentUrl, get(changeset, '_contacts'));
        if (get(changeset, 'password')) {
          //Set password. Due to API returns empty object from POST /password, i can't
          //use DS Model here but a directly ajax request.
          promises.push(_this.savePassword(get(changeset, 'password'), response.id));
        }
        return Ember.RSVP.all(promises).then(function () {
          return _this.transitionToRoute('users.show', response.id);
        });
      }).catch(function (response) {
        _this.errorHandler(response.message);
      });
    },
    savePassword: function savePassword(password, userId) {
      var _this = this;
      return Ember.$.ajax({
        url: _environment.default.apiUrl + '/users/' + userId + '/password',
        data: JSON.stringify({ password: password }),
        type: 'POST',
        xhrFields: { withCredentials: true },
        contentType: 'application/json;charset=utf-8',
        dataType: 'json'
      });
    },
    exportUsers: function exportUsers() {
      var _this = this;
      return Ember.$.ajax({
        url: _environment.default.apiUrl + '/users/export',
        type: 'GET',
        xhrFields: { withCredentials: true },
        xhr: function xhr() {
          var xhr = new XMLHttpRequest();
          xhr.onreadystatechange = function () {
            if (xhr.readyState == 2) {
              if (xhr.status == 200) {
                xhr.responseType = "blob";
              } else {
                xhr.responseType = "text";
              }
            }
          };
          return xhr;
        }
      }).then(function (response) {
        var url = window.URL.createObjectURL(new Blob([response]));
        var link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'users_' + new Date().getTime() + '.csv');
        document.body.appendChild(link);
        link.click();
      });
    },

    actions: {
      validateProperty: function validateProperty(changeset, property) {
        return changeset.validate(property);
      },
      cancel: function cancel(changeset) {
        if (confirm('Are you sure you want to leave this page?')) {
          changeset.rollback();
          this.transitionToRoute('users');
        }
      }
    }
  });
});