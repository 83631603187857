define('motorplanit/models/guardian', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    relationshipId: (0, _attr.default)('string'),
    otherRelationship: (0, _attr.default)('string'),
    title: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    photoFileId: (0, _attr.default)('string'),
    about: (0, _attr.default)('string')
  });
});