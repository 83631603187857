define('motorplanit/controllers/application', ['exports', 'motorplanit/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isLoginRoute: Ember.computed.match('currentRouteName', /(login|forgot_password|change_password|email_sent)/),
    noLayout: Ember.computed.match('currentRouteName', /(not-found|forbidden)/),
    actions: {
      logout: function logout() {
        var _this = this;
        Ember.$.ajax({
          url: _environment.default.apiUrl + '/user/signOut',
          type: 'POST',
          xhrFields: { withCredentials: true },
          contentType: 'application/json;charset=utf-8',
          dataType: 'json'
        }).then(function () {
          location.href = '/login';
        });
      }
    }
  });
});