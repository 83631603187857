define('motorplanit/validations/change-password', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    password: (0, _validators.validatePresence)(true),
    passwordConfirm: (0, _validators.validateConfirmation)({ on: 'password' })
  };
});