define('motorplanit/components/left-sidebar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    permissions: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.menu = [];
      this.addUsersMenu();
      this.addClinicsMenu();
      this.addActivitiesMenu();
      this.addGlossaryMenu();
      this.addDevicesMenu();
    },
    didInsertElement: function didInsertElement() {
      $('#menu').metisMenu();
      this.flag = true;
    },
    didUpdate: function didUpdate() {
      if (this.flag) $('ul.navigation > li.active > ul.sub-menu').addClass('in');
      this.flag = false;
    },
    addUsersMenu: function addUsersMenu() {
      if (!this.get('permissions').canViewUsers()) return;
      if (this.get('permissions').isGuardian()) {
        this.menu.push({ name: 'Users', route: 'users.index' });
        return;
      }

      var usersMenu = {
        name: 'Users',
        subMenu: [{ name: 'Users', route: 'users.index' }, { name: 'Add Users', route: 'users.new' }]
      };

      if (this.get('permissions').isSuperAdmin()) {
        usersMenu.subMenu.unshift({ name: 'Groups', route: 'groups' });
      }
      this.menu.push(usersMenu);
    },
    addClinicsMenu: function addClinicsMenu() {
      if (!this.get('permissions').canViewClinics()) return;
      var clinicsMenu = void 0;
      if (this.get('permissions').isSuperAdmin()) {
        clinicsMenu = {
          name: 'Clinics',
          subMenu: [{ name: 'Clinics', route: 'clinics.index' }, { name: 'Add Clinic', route: 'clinics.new' }]
        };
      } else if (this.get('permissions').clinicId()) {
        clinicsMenu = {
          name: 'Clinic Details',
          route: 'clinics.show',
          id: this.get('permissions').clinicId()
        };
      }
      if (clinicsMenu) {
        this.menu.push(clinicsMenu);
      }
    },
    addActivitiesMenu: function addActivitiesMenu() {
      if (!this.get('permissions').canViewActivities()) return;
      var activitiesMenu = void 0;

      if (this.get('permissions').isSuperAdmin()) {
        activitiesMenu = {
          name: 'Activities',
          subMenu: [{ name: 'Activities', route: 'activities.index' }, { name: 'Add Activity', route: 'activities.new' }]
        };
      } else {
        activitiesMenu = { name: 'Activities', route: 'activities.index' };
      }
      this.menu.push(activitiesMenu);
    },
    addGlossaryMenu: function addGlossaryMenu() {
      var glossaryMenu = { name: 'Glossary', route: 'glossary.index' };
      this.menu.push(glossaryMenu);
    },
    addDevicesMenu: function addDevicesMenu() {
      if (this.get('permissions.hasEditDevicesPerms')) {
        var devicesMenu = { name: 'Devices', route: 'devices.index' };
        this.menu.push(devicesMenu);
      }
    }
  });
});