define('motorplanit/routes/devices/edit', ['exports', 'motorplanit/mixins/authenticated-route'], function (exports, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRoute.default, {
    permissions: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get('permissions.hasEditDevicesPerms')) {
        return this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      return Ember.RSVP.hash({
        device: this.get('store').findAll('device').then(function (response) {
          return response.findBy('id', params.device_id);
        }),
        clinics: this.get('store').findAll('clinic')
      });
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model.device);
      controller.set('clinics', model.clinics);
    }
  });
});