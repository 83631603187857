define('motorplanit/controllers/activities/edit', ['exports', 'motorplanit/config/environment', 'motorplanit/mixins/activities-controller'], function (exports, _environment, _activitiesController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Controller.extend(_activitiesController.default, {
    actions: {
      save: function save(changeset, nestedLevels) {
        console.log('SAVE');
        var _this = this;
        var activityId = get(changeset, 'id');
        var content = Ember.Object.create(changeset._content);
        var changes = Ember.Object.create(changeset._changes);
        var data = Ember.merge(content, changes);

        data = this.prepareData(data);
        var isInvalid = this.validateAllChangesets(changeset, nestedLevels);
        if (isInvalid) return;

        return changeset.validate().then(function () {
          if (get(changeset, 'isValid')) {
            if (get(changeset, 'avatar')) {
              var formData = new FormData();
              var typeId = _this.fileTypes.findBy('name', 'Image').id;
              var dataFile = JSON.stringify({ typeId: typeId });
              formData.append('file', changeset._changes.avatar);
              formData.append('data', dataFile);

              Ember.$.ajax({
                url: _environment.default.apiUrl + '/files',
                data: formData,
                xhrFields: { withCredentials: true },
                type: 'POST',
                contentType: false,
                processData: false
              }).then(function (response) {
                data.avatarFileId = response.item.id;
                _this.updateActivity(data, nestedLevels, activityId, _this);
              });
            } else {
              _this.updateActivity(data, nestedLevels, activityId, _this);
            }
          }
        });
      }
    }
  });
});