define('motorplanit/validators/activity-sensory', ['exports', 'motorplanit/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var validateSensoryActivityType = function validateSensoryActivityType(label) {
    return function (key, newValue, oldValue, changes) {
      var typeId = changes.typeId;

      // We need to query the activity types to match the activity type id, otherwise it will be really hard to modify the 
      // hasExtendedDetails field dynamically using jquery.
      return new Promise(function (resolve) {
        Ember.$.ajax({
          url: _environment.default.apiUrl + '/activityTypes',
          xhrFields: { withCredentials: true },
          type: 'GET',
          contentType: 'application/json;charset=utf-8',
          dataType: 'json'
        }).then(function (activityTypes) {
          if (!typeId) {
            resolve(true);
          }

          var activityType = activityTypes.items.find(function (activityType) {
            return activityType.id === typeId;
          });

          if (activityType && typeId && !newValue && activityType.name === "Sensory") {
            resolve(label + ' is required.');
          }

          resolve(true);
        });
      });
    };
  };

  exports.default = validateSensoryActivityType;
});