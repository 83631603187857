define('motorplanit/mixins/devices-controller', ['exports', 'motorplanit/validations/device', 'motorplanit/mixins/global-error-handler', 'motorplanit/config/environment'], function (exports, _device, _globalErrorHandler, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Mixin.create(_globalErrorHandler.default, {
    DeviceValidations: _device.default,
    saveDeviceAndSetPassword: function saveDeviceAndSetPassword(changeset) {
      var _this = this;

      return changeset.save().then(function (response) {
        if (get(changeset, 'password')) {
          //Set password. Due to API returns empty object from POST /password, i can't
          //use DS Model here but a directly ajax request.
          _this.savePassword(get(changeset, 'password'), response.id);
        } else {
          _this.transitionToRoute('devices.index');
        }
      }).catch(function (message) {
        _this.set('errorMessage', message);
      });
    },
    savePassword: function savePassword(password, deviceId) {
      var _this = this;
      return Ember.$.ajax({
        url: _environment.default.apiUrl + '/devices/' + deviceId + '/password',
        data: JSON.stringify({ password: password }),
        type: 'POST',
        xhrFields: { withCredentials: true },
        contentType: 'application/json;charset=utf-8',
        dataType: 'json'
      }).then(function () {
        return _this.transitionToRoute('devices.index');
      }).catch(function (message) {
        _this.set('errorMessage', message);
      });
    },

    actions: {
      validateProperty: function validateProperty(changeset, property) {
        return changeset.validate(property);
      },
      cancel: function cancel(changeset) {
        if (confirm('Are you sure you want to leave this page?')) {
          changeset.rollback();
          this.transitionToRoute('devices');
        }
      }
    }

  });
});