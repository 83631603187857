define('motorplanit/models/location', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    timeZoneId: (0, _attr.default)('string'),
    timeZone: (0, _attr.default)(),
    contacts: _emberData.default.hasMany('contact', { inverse: null }),
    clinic: _emberData.default.belongsTo('clinic', { inverse: null })
  });
});