define('motorplanit/mixins/activities-routes-common', ['exports', 'motorplanit/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    _findActivityById: function _findActivityById(activityId) {
      return Ember.$.ajax({
        url: _environment.default.apiUrl + '/activities/' + activityId,
        xhrFields: { withCredentials: true },
        type: 'GET',
        contentType: 'application/json;charset=utf-8',
        dataType: 'json'
      }).then(function (response) {
        console.log('response of _findActivityById');
        console.log(response);
        return response.item;
      });
    },
    getActivityLevelTypes: function getActivityLevelTypes() {
      return Ember.$.ajax({
        url: _environment.default.apiUrl + '/activityLevelTypes',
        xhrFields: { withCredentials: true },
        type: 'GET',
        contentType: 'application/json;charset=utf-8',
        dataType: 'json'
      }).then(function (response) {
        return response.items.sortBy('order');
      });
    },
    getSensorySystems: function getSensorySystems() {
      return Ember.$.ajax({
        url: _environment.default.apiUrl + '/sensorySystems',
        xhrFields: { withCredentials: true },
        type: 'GET',
        contentType: 'application/json;charset=utf-8',
        dataType: 'json'
      }).then(function (response) {
        return response.items;
      });
    },
    _setModel: function _setModel(controller, model) {
      controller.set('model', model.activity);
      controller.set('activityTypes', model.activityTypes);
      controller.set('activityLevelTypes', model.activityLevelTypes);
      controller.set('fileTypes', model.fileTypes);
      controller.set('sensorySystems', model.sensorySystems);

      var hasExtendedDetails = model.activityTypes.map(function (activityType) {
        if (activityType.get('hasExtendedDetails')) {
          return activityType.id;
        }
      }).compact();

      controller.set('hasExtendedDetails', hasExtendedDetails);
    }
  });
});