define('motorplanit/serializers/contact', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      contactType: { embedded: 'always' }
    },
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      payload = {
        contacts: payload.items
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      var attrs = Ember.merge(hash, this.serialize(record, options));
      attrs.data = attrs._data;
      return attrs;
    },
    normalizeSaveResponse: function normalizeSaveResponse(store, primaryModelClass, payload, id, requestType) {
      var contact = payload.item;
      contact._data = contact.data;
      contact.contactType = contact.type;
      delete contact['data'];
      delete contact['type'];

      payload = {
        contact: contact
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});