define('motorplanit/controllers/users/index', ['exports', 'motorplanit/mixins/users-controller'], function (exports, _usersController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_usersController.default, {
    permissions: Ember.inject.service(),
    queryParams: ['groupId'],
    groupId: null,
    actions: {
      exportUsers: function exportUsers() {
        this.exportUsers();
      }
    }
  });
});