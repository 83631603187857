define("motorplanit/validations/relation", ["exports", "ember-changeset-validations/validators"], function (exports, _validators) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    userId: (0, _validators.validatePresence)({ presence: true, message: "Patient can't be blank. Please select an option." }),
    relationshipId: (0, _validators.validatePresence)({ presence: true, message: "Relationship can't be blank. Please select an option." })
  };
});