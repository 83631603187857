define('motorplanit/models/device', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    userName: (0, _attr.default)('string'),
    clinicLocationId: (0, _attr.default)('string'),
    clinicId: (0, _attr.default)('string'),
    clinicLocation: _emberData.default.belongsTo('location', { inverse: null }),
    clinic: _emberData.default.belongsTo('clinic', { inverse: null }),
    password: (0, _attr.default)('string')
  });
});