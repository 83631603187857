define('motorplanit/models/level-step', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    description: (0, _attr.default)('string'),
    order: (0, _attr.default)('number'),
    imageFileId: (0, _attr.default)('string'),
    videoFileId: (0, _attr.default)('string'),
    level: _emberData.default.belongsTo('level'),
    image: (0, _attr.default)('file'),
    video: (0, _attr.default)('file'),
    videoUrl: (0, _attr.default)('string')
  });
});