define('motorplanit/validations/user', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    firstName: (0, _validators.validatePresence)(true),
    lastName: (0, _validators.validatePresence)(true),
    userName: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({ type: 'email' })],
    password: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({ min: 8 })]
  };
});