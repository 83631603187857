define("motorplanit/components/login-layout", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      $("body").css({ background: "#31235b" });
    }
  });
});