define('motorplanit/models/clinic', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: (0, _attr.default)('string'),
    logoFileId: (0, _attr.default)('string'),
    logoFile: (0, _attr.default)('file'),
    about: (0, _attr.default)('string'),
    logo: (0, _attr.default)('file'),
    contacts: _emberData.default.hasMany('contact', { inverse: null }),
    locations: _emberData.default.hasMany('location', { inverse: null }),
    devices: _emberData.default.hasMany('device', { inverse: null }),
    admins: _emberData.default.hasMany('user', { inverse: null }),
    numberOfDeviceLicenses: (0, _attr.default)('number')
  });
});