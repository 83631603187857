define('motorplanit/components/patient-report', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didRender: function didRender() {
      if ($('#report-table').length) {
        $('#report-table').DataTable();
      }
    },


    rowColor: function rowColor(index, group) {
      return group.colorHash;
    },

    hexToRgb: function hexToRgb(hex) {
      // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
      });

      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      } : null;
    },


    groups: Ember.computed('model.activityProgresses.[]', function () {
      var groups = Ember.A();
      var progresses = this.get('model.activityProgresses');
      if (!progresses) {
        return groups;
      }

      var _this = this;
      progresses.forEach(function (item, index) {
        var existing = groups.filter(function (group) {
          return group.id === item.type.id;
        }).get('firstObject');

        if (existing) {
          var index = existing.items.get('count');
          if (index % 2 !== 0) {
            var color = _this.hexToRgb(item.type.color);
            item.backgroundColor = 'rgba({color.r}, {color.g}, {color.b}, 0.2)';
          }
          existing.items.pushObject(item);
        } else {
          var group = {
            id: item.type.id,
            colorHash: item.type.color,
            order: item.type.order,
            name: item.type.name,
            completionType: item.type.completionType,
            items: Ember.A()
          };
          group.items.pushObject(item);
          groups.pushObject(group);
        }
      });

      return groups.sortBy('order');
    }),

    actions: {
      openActivity: function openActivity(activityId) {
        window.open();
      }
    }
  });
});