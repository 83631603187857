define('motorplanit/serializers/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      contacts: { embedded: 'always' },
      guardians: { embedded: 'always' },
      relations: { embedded: 'always' }
    },

    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      payload = {
        users: payload.items
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeQueryRecordResponse: function normalizeQueryRecordResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.item.contacts) {
        payload.item.contacts = this._normalizeContacts(payload.item.contacts);
      }
      if (payload.item.guardians) {
        payload.item.guardians = this._normalizeGuardianContacts(payload.item.guardians);
      }

      payload = {
        user: payload.item
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.item.contacts) {
        payload.item.contacts = this._normalizeContacts(payload.item.contacts);
      }
      if (payload.item.guardians) {
        payload.item.guardians = this._normalizeGuardianContacts(payload.item.guardians);
      }

      payload = {
        user: payload.item
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      Ember.merge(hash, this.serialize(record, options));
    },
    normalizeSaveResponse: function normalizeSaveResponse(store, primaryModelClass, payload, id, requestType) {
      if (payload.item.contacts) {
        payload.item.contacts = this._normalizeContacts(payload.item.contacts);
      }
      if (payload.item.guardians) {
        payload.item.guardians = this._normalizeGuardianContacts(payload.item.guardians);
      }

      payload = {
        user: payload.item
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    _normalizeGuardianContacts: function _normalizeGuardianContacts(guardians) {
      var _this = this;

      if (!guardians) return [];
      guardians = guardians.map(function (guardian) {
        guardian.contacts = _this._normalizeContacts(guardian.contacts);
        return guardian;
      });
      return guardians;
    },

    // Need to change attributes from API like 'type', 'data' reserved words.
    _normalizeContacts: function _normalizeContacts(contacts) {
      var newContacts = Ember.A();
      newContacts = contacts.map(function (contact) {
        contact._data = contact.data;
        contact.contactType = contact.type;
        delete contact['data'];
        delete contact['type'];
        return contact;
      });
      return newContacts;
    }
  });
});