define('motorplanit/routes/clinics/new', ['exports', 'motorplanit/mixins/authenticated-route', 'motorplanit/mixins/clinics-routes-common'], function (exports, _authenticatedRoute, _clinicsRoutesCommon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRoute.default, _clinicsRoutesCommon.default, {
    permissions: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get('permissions').isSuperAdmin()) {
        return this.transitionTo('forbidden');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        clinic: this.get('store').createRecord('clinic'),
        fileTypes: this.get('store').findAll('fileType'),
        contactTypes: this.get('store').findAll('contactType')
      });
    },

    setupController: function setupController(controller, model) {
      this.setModel(controller, model);
    }
  });
});