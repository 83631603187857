define('motorplanit/mixins/activities-controller', ['exports', 'motorplanit/validations/activity', 'motorplanit/config/environment', 'motorplanit/models/activity'], function (exports, _activity, _environment, _activity2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var get = Ember.get;
  var keys = Object.keys;
  exports.default = Ember.Mixin.create({
    ActivityValidations: _activity.default,
    actions: {
      cancel: function cancel(changeset) {
        if (confirm('Are you sure you want to leave this page?')) {
          changeset.rollback();
          this.transitionToRoute('activities');
        }
      },
      validateProperty: function validateProperty(changeset, property) {
        return changeset.validate(property);
      },
      handleTypeIdChange: function handleTypeIdChange(changeset, value) {
        changeset.set('typeId', value);
        if (get(changeset, 'isInvalid')) {
          var fields = ['sensometer', 'goals', 'tips', 'clinicalFocus', 'homeAndBeyond'];
          fields.forEach(function (field) {
            changeset.validate(field);
          });
        }
      }
    },
    prepareData: function prepareData(data) {
      //Clear data not requied in order to avoid bad request error from API.
      var activityTypeId = data.typeId;
      var activityType = this.activityTypes.find(function (item) {
        return item.id == activityTypeId;
      });

      if (activityType) {
        if (!activityType.hasColor) data.color = null;
        if (!activityType._internalModel._data.isAvatarUsed) data.avatar = data.avatarFile = data.avatarFileId = null;
        if (!activityType.hasExtendedDetails) {
          data.goals = data.sensometer = data.tips = data.clinicalFocus = data.homeAndBeyond = null;
        }
        if (data.type && data.type.name === 'Sensory' && !data.isHome) {
          data.goals = data.sensometer = data.tips = data.clinicalFocus = data.homeAndBeyond = data.modifications = data.materials = null;
        }
      }

      // Set null when a property is empty string. To avoid error in API
      keys(_activity2.schema).forEach(function (field) {
        if (data[field] === '') {
          data[field] = null;
        }
      });

      return data;
    },
    validateAllChangesets: function validateAllChangesets(changeset, nestedLevels) {
      var isInvalid = false;
      changeset.validate();
      isInvalid = get(changeset, 'isInvalid');

      nestedLevels.forEach(function (levelData) {
        levelData.level.validate();
        if (get(levelData.level, 'isInvalid')) isInvalid = true;
        levelData.steps.forEach(function (step) {
          var stepId = get(step, 'id');

          if (!stepId) {
            step.validate();
            if (get(step, 'isInvalid')) isInvalid = true;
          }
        });
      });

      return isInvalid;
    },
    createActivity: function createActivity(data, nestedLevels, _this) {
      Ember.$.ajax({
        url: _environment.default.apiUrl + '/activities',
        data: JSON.stringify(data),
        xhrFields: { withCredentials: true },
        type: 'POST',
        contentType: 'application/json;charset=utf-8',
        dataType: 'json'
      }).then(function (response) {
        var promises = [_this.saveSensorySystems(data, response.item.id).flat(), _this.saveLevels(nestedLevels, response.item.id, _this).flat()].flat().filter(function (notUndefined) {
          return notUndefined !== undefined;
        });
        Promise.all(promises).then(function () {
          _this.transitionToRoute('activities.activity', response.item.id);
        }).catch(function (error) {
          if ($('.error-message').length === 0) $('.form').append('<div class="error-message"><strong>Error:</strong> ' + error + '</div>');
        });
      }).catch(function () {
        if ($('.error-message').length === 0) $('.form').append('<div class="error-message"><strong>Error:</strong> Invalid data</div>');
      });
    },
    updateActivity: function updateActivity(data, nestedLevels, activityId, _this) {
      Ember.$.ajax({
        url: _environment.default.apiUrl + '/activities/' + activityId,
        data: JSON.stringify(data),
        xhrFields: { withCredentials: true },
        type: 'PUT',
        contentType: 'application/json;charset=utf-8',
        dataType: 'json'
      }).then(function (response) {
        var promises = [_this.saveSensorySystems(data, response.item.id).flat(), _this.saveLevels(nestedLevels, activityId, _this).flat()].flat().filter(function (notUndefined) {
          return notUndefined !== undefined;
        });
        Promise.all(promises).then(function () {
          _this.transitionToRoute('activities.activity', response.item.id);
        }).catch(function (error) {
          if ($('.error-message').length === 0) $('.form').append('<div class="error-message"><strong>Error:</strong> ' + error + '</div>');
        });
      }).catch(function () {
        if ($('.error-message').length === 0) $('.form').append('<div class="error-message"><strong>Error:</strong> Invalid data</div>');
      });
    },
    saveSensorySystems: function saveSensorySystems(data, activityId) {
      if (!data.newSensorySystems) return [];
      var sensorySystems = data.sensorySystems;
      var newSensorySystems = data.newSensorySystems.mapBy("id");
      var sensorySystemsExcluded = [];
      var excludeSensorySystemsPromises = [];
      var addSensorySystemsPromises = [];

      if (sensorySystems) {
        var sensorySystemsArray = sensorySystems.mapBy("sensorySystem.id");
        sensorySystemsExcluded = sensorySystems.reject(function (item) {
          return newSensorySystems.includes(item.sensorySystem.id);
        });
        newSensorySystems = newSensorySystems.reject(function (item) {
          return sensorySystemsArray.includes(item);
        });
      }

      // Delete sensorySystems not included anymore
      excludeSensorySystemsPromises = sensorySystemsExcluded.map(function (sensorySystem) {
        return Ember.$.ajax({
          url: _environment.default.apiUrl + '/activities/' + activityId + '/sensorySystems/' + sensorySystem.id,
          xhrFields: { withCredentials: true },
          type: 'DELETE',
          contentType: 'application/json;charset=utf-8'
        });
      });

      //Add new sensorySystems
      addSensorySystemsPromises = newSensorySystems.map(function (sensorySystemId) {
        var data = { sensorySystemId: sensorySystemId };
        return Ember.$.ajax({
          url: _environment.default.apiUrl + '/activities/' + activityId + '/sensorySystems',
          data: JSON.stringify(data),
          xhrFields: { withCredentials: true },
          type: 'POST',
          contentType: 'application/json;charset=utf-8',
          dataType: 'json'
        });
      });

      return [].concat(_toConsumableArray(excludeSensorySystemsPromises), _toConsumableArray(addSensorySystemsPromises));
    },
    saveLevels: function saveLevels(levels, activityId, _this) {
      return levels.map(function (levelData) {
        var changesetLevel = levelData.level;
        var stepChangesets = levelData.steps;
        var levelId = get(changesetLevel, 'id');
        var promises = [];

        if (get(changesetLevel, 'isDirty')) {
          promises.push(changesetLevel.validate().then(function () {
            if (get(changesetLevel, 'isValid')) {

              var content = Ember.Object.create(changesetLevel._content);
              var changes = Ember.Object.create(changesetLevel._changes);

              if (levelId) {
                var data = Ember.merge(content, changes);
                data.typeId = content.type.id;

                return Ember.$.ajax({
                  url: _environment.default.apiUrl + '/activities/' + activityId + '/levels/' + levelId,
                  data: JSON.stringify(data),
                  xhrFields: { withCredentials: true },
                  type: 'PUT',
                  contentType: 'application/json;charset=utf-8',
                  dataType: 'json'
                }).then(function (response) {
                  return _this.saveLevelSteps(stepChangesets, activityId, response.item.id, _this);
                });
              } else {
                return Ember.$.ajax({
                  url: _environment.default.apiUrl + '/activities/' + activityId + '/levels',
                  data: JSON.stringify(changes),
                  xhrFields: { withCredentials: true },
                  type: 'POST',
                  contentType: 'application/json;charset=utf-8',
                  dataType: 'json'
                }).then(function (response) {
                  return _this.saveLevelSteps(stepChangesets, activityId, response.item.id, _this);
                });
              }
            }
          }).catch(function () {
            if ($('.error-message').length === 0) $('.form').append('<div class="error-message"><strong>Error:</strong> Invalid data</div>');
          }));
        } else if (levelId) {
          promises.push(_this.saveLevelSteps(stepChangesets, activityId, levelId, _this));
        }

        //Remove Level Steps
        if (levelId) {
          promises.push(_this.deleteLevelSteps(activityId, changesetLevel, stepChangesets));
        }

        return promises.flat();
      });
    },
    saveLevelSteps: function saveLevelSteps(stepChangesets, activityId, levelId, _this) {
      return stepChangesets.map(function (stepChangeset) {
        if (get(stepChangeset, 'isDirty')) {
          if (stepChangeset._changes.image) {
            return _this.uploadImage(stepChangeset, activityId, levelId, _this);
          } else if (stepChangeset._changes.video) {
            return _this.uploadVideo(stepChangeset, activityId, levelId, _this);
          } else {
            return _this.saveLevelStep(stepChangeset, activityId, levelId, stepChangeset._changes.imageFileId);
          }
        }
      });
    },
    deleteLevelSteps: function deleteLevelSteps(activityId, changesetLevel, stepChangesets) {
      var leveld = get(changesetLevel, 'id');
      var levelSteps = get(changesetLevel, 'steps');
      var newLevelSteps = stepChangesets.mapBy("id");
      var levelStepsExcluded = levelSteps.reject(function (item) {
        return newLevelSteps.includes(item.id);
      });

      return levelStepsExcluded.map(function (step) {
        return Ember.$.ajax({
          url: _environment.default.apiUrl + '/activities/' + activityId + '/levels/' + leveld + '/steps/' + step.id,
          xhrFields: { withCredentials: true },
          type: 'DELETE',
          contentType: 'application/json;charset=utf-8'
        });
      });
    },
    uploadImage: function uploadImage(stepChangeset, activityId, levelId, _this) {
      var formData = new FormData();
      var typeId = _this.fileTypes.findBy('name', 'Image').id;
      var data = JSON.stringify({ typeId: typeId });
      formData.append('file', stepChangeset._changes.image);
      formData.append('data', data);

      return Ember.$.ajax({
        url: _environment.default.apiUrl + '/files',
        data: formData,
        xhrFields: { withCredentials: true },
        type: 'POST',
        contentType: false,
        processData: false
      }).then(function (response) {
        if (stepChangeset._changes.video) {
          return _this.uploadVideo(stepChangeset, activityId, levelId, _this, response.item.id);
        } else {
          return _this.saveLevelStep(stepChangeset, activityId, levelId, response.item.id);
        }
      });
    },
    uploadVideo: function uploadVideo(stepChangeset, activityId, levelId, _this, imageFileId) {
      var formData = new FormData();
      var typeId = _this.fileTypes.findBy('name', 'Video').id;
      var data = JSON.stringify({ typeId: typeId });
      formData.append('file', stepChangeset._changes.video);
      formData.append('data', data);

      return Ember.$.ajax({
        url: _environment.default.apiUrl + '/files',
        data: formData,
        xhrFields: { withCredentials: true },
        type: 'POST',
        contentType: false,
        processData: false
      }).then(function (response) {
        return _this.saveLevelStep(stepChangeset, activityId, levelId, imageFileId, response.item.id);
      });
    },
    saveLevelStep: function saveLevelStep(stepChangeset, activityId, levelId, imageFileId, videoFileId) {
      var stepId = get(stepChangeset, 'id');
      var changes = Ember.Object.create(stepChangeset._changes);
      var content = Ember.Object.create(stepChangeset._content);

      if (stepId) {
        var data = Ember.assign(content, changes);
        data.imageFileId = content.imageFile.id;
        if (content.videoFile) {
          data.videoFileId = content.videoFile.id;
        }

        if (imageFileId) {
          data.imageFileId = imageFileId;
        }
        if (videoFileId) {
          data.videoFileId = videoFileId;
        }

        return Ember.$.ajax({
          url: _environment.default.apiUrl + '/activities/' + activityId + '/levels/' + levelId + '/steps/' + stepId,
          data: JSON.stringify(data),
          xhrFields: { withCredentials: true },
          type: 'PUT',
          contentType: 'application/json;charset=utf-8',
          dataType: 'json'
        });
      } else {
        changes.order = content._internalModel.__attributes.order;
        if (imageFileId) {
          changes.imageFileId = imageFileId;
        }

        return Ember.$.ajax({
          url: _environment.default.apiUrl + '/activities/' + activityId + '/levels/' + levelId + '/steps',
          data: JSON.stringify(changes),
          xhrFields: { withCredentials: true },
          type: 'POST',
          contentType: 'application/json;charset=utf-8',
          dataType: 'json'
        });
      }
    }
  });
});