define('motorplanit/controllers/change-password', ['exports', 'motorplanit/validations/change-password', 'motorplanit/config/environment'], function (exports, _changePassword, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ChangePasswordValidations: _changePassword.default,
    queryParams: ['id', 'token'],
    id: null,
    token: null,
    actions: {
      changePassword: function changePassword(changeset) {
        changeset.set('id', this.get('id'));
        changeset.set('token', this.get('token'));

        var _this = this;

        changeset.validate().then(function () {
          if (changeset.get('isValid') === false) {
            return;
          }

          var data = {
            id: _this.get('id'),
            token: _this.get('token'),
            password: changeset.get('password')
          };

          Ember.$.ajax({
            url: _environment.default.apiUrl + '/user/resetPassword',
            data: JSON.stringify(data),
            xhrFields: { withCredentials: true },
            type: 'POST',
            contentType: 'application/json;charset=utf-8',
            dataType: 'json'
          });

          _this.transitionToRoute('login');
        });
      },
      cancel: function cancel(changeset) {
        changeset.rollback();
        return this.transitionToRoute('login');
      },
      validateProperty: function validateProperty(changeset, property) {
        return changeset.validate(property);
      }
    }

  });
});