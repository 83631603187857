define('motorplanit/controllers/login', ['exports', 'motorplanit/validations/login', 'motorplanit/models/login', 'motorplanit/config/environment', 'motorplanit/mixins/authenticated-route'], function (exports, _login, _login2, _environment, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  var keys = Object.keys;
  exports.default = Ember.Controller.extend(_authenticatedRoute.default, {
    permissions: Ember.inject.service(),
    LoginValidations: _login.default,

    actions: {
      authenticate: function authenticate(changeset) {
        var _this = this;
        var snapshot = changeset.snapshot();
        return changeset.cast(keys(_login2.schema)).validate().then(function () {
          if (get(changeset, 'isValid')) {
            var _changeset$getPropert = changeset.getProperties('username', 'password'),
                username = _changeset$getPropert.username,
                password = _changeset$getPropert.password;

            Ember.$.ajax({
              url: _environment.default.apiUrl + '/user/signIn',
              type: 'POST',
              xhrFields: { withCredentials: true },
              data: JSON.stringify({
                username: username,
                password: password
              }),
              contentType: 'application/json;charset=utf-8',
              dataType: 'json'
            }).then(function (response) {
              _this.get('permissions').set('cUser', response.item);
              return _this.transitionToRoute('index');
            }).catch(function (response) {
              if (response.status && response.status === 400 && $('.error-message').length === 0) $('.content-form').append('<div class="error-message"><strong>Error:</strong> Invalid username or password.</div>');
            });
          }
        }).catch(function () {
          changeset.restore(snapshot);
        });
      },
      reset: function reset(changeset) {
        return changeset.rollback();
      },
      validateProperty: function validateProperty(changeset, property) {
        return changeset.validate(property);
      }
    }
  });
});