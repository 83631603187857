define('motorplanit/routes/users/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    permissions: Ember.inject.service(),
    model: function model(params) {
      return this.get('store').findRecord('user', params.user_id, { reload: true });
    }
  });
});