define('motorplanit/controllers/glossary/new', ['exports', 'motorplanit/mixins/glossary-terms-controller'], function (exports, _glossaryTermsController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_glossaryTermsController.default, {
    actions: {
      save: function save(changeset) {
        var _this = this;

        return changeset.save().then(function (response) {
          _this.transitionToRoute('glossary.show', response.id);
        }).catch(function () {
          _this.errorHandler();
        });
      }
    }
  });
});