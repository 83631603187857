define('motorplanit/controllers/clinics/show', ['exports', 'motorplanit/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    permissions: Ember.inject.service(),
    currentTab: "locations",
    actions: {
      delete: function _delete(model) {
        var confirmed = window.confirm('Are you sure you want to delete this Clinic?');
        var _this = this;

        if (confirmed) {
          Ember.$.ajax({
            url: _environment.default.apiUrl + '/clinics/' + model.id,
            xhrFields: { withCredentials: true },
            type: 'DELETE',
            contentType: 'application/json;charset=utf-8'
          }).then(function () {
            return _this.transitionToRoute('clinics');
          });
        }
      },
      editLocation: function editLocation(clinic, location) {
        this.transitionToRoute('clinics.locations.edit', clinic.id, location.id);
      },
      deleteLocation: function deleteLocation(model, location) {
        var confirmed = window.confirm('Are you sure you want to delete this Location?');
        var _this = this;

        if (confirmed) {
          Ember.$.ajax({
            url: _environment.default.apiUrl + '/clinics/' + model.id + '/locations/' + location.id,
            xhrFields: { withCredentials: true },
            type: 'DELETE',
            contentType: 'application/json;charset=utf-8'
          }).then(function () {
            var locationD = model.locations.findBy('id', location.id);
            model.locations.removeObject(locationD);
            _this.set('model', model);
          });
        }
      },
      showTab: function showTab(tab) {
        this.set('currentTab', tab);
      }
    }
  });
});