define('motorplanit/mixins/global-error-handler', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    errorHandler: function errorHandler(message) {
      if ($('.error-message').length === 1) {
        $('.error-message').removeClass('hidden').html('<strong>Error:</strong> Invalid data. <br/>' + (message || ""));
      } else {
        $('.content-form').append('<div class="error-message"><strong>Error:</strong> Invalid data. <br/>' + (message || "") + '</div>');
      }
    }
  });
});