define('motorplanit/services/permissions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    role: function role() {
      return this.get('cUser.applicationRole');
    },
    isSuperAdmin: function isSuperAdmin() {
      return this.role().isSuperAdmin;
    },
    isClinicAdmin: function isClinicAdmin() {
      return this.role().isClinicAdmin;
    },
    isTherapist: function isTherapist() {
      return this.role().isTherapist;
    },
    isGuardian: function isGuardian() {
      return this.role().isGuardian;
    },
    areAdmins: function areAdmins() {
      return ['Super Admin', 'Clinic Admin'].includes(this.role().name);
    },
    canViewUsers: function canViewUsers() {
      return ['Super Admin', 'Clinic Admin', 'Therapist', 'Guardian'].includes(this.role().name);
    },
    canViewClinics: function canViewClinics() {
      return this.areAdmins();
    },
    clinicId: function clinicId() {
      if (this.get('cUser').clinic) {
        return this.get('cUser').clinic.id;
      }
      return;
    },
    isClinicRelated: function isClinicRelated(clinicId) {
      return this.clinicId() === clinicId;
    },
    canViewActivities: function canViewActivities() {
      return ['Super Admin', 'Clinic Admin', 'Therapist'].includes(this.role().name);
    },
    canViewAppReleases: function canViewAppReleases() {
      return this.areAdmins();
    },
    canEditGlossary: function canEditGlossary() {
      return ['Super Admin', 'Guardian'].includes(this.role().name);
    },
    hasEditUsersPerms: function hasEditUsersPerms() {
      return ['Super Admin', 'Clinic Admin', 'Therapist'].includes(this.role().name);
    },
    canEditUser: function canEditUser(user) {
      //Super Admin can edit all users. Also if the user edit its profile.
      if (this.isSuperAdmin() || this.get('cUser').id === user.id) {
        return true;
      }

      var userRoleName = user.get('applicationRole.name');

      if (this.isClinicAdmin()) {
        return ['Clinic Admin', 'Therapist', 'Guardian', 'Patient'].includes(userRoleName);
      }
      if (this.isTherapist()) {
        return ['Guardian', 'Patient'].includes(userRoleName);
      }
      return false;
    },
    renderDefaultRoute: function renderDefaultRoute() {
      if (this.isSuperAdmin()) {
        return 'clinics.index';
      }
      if (this.isClinicAdmin() || this.isTherapist() || this.isGuardian()) {
        return 'users.index';
      }
      return 'users.index';
    },

    _isSuperAdmin: Ember.computed(function () {
      return this.isSuperAdmin();
    }),
    hasEditGlossaryPerms: Ember.computed(function () {
      return this.canEditGlossary();
    }),
    canAddUser: Ember.computed(function () {
      return this.hasEditUsersPerms();
    }),
    hasEditDevicesPerms: Ember.computed(function () {
      return this.isClinicAdmin() || this.isSuperAdmin();
    }),
    hasEditRelationshipPerms: Ember.computed(function () {
      return this.isClinicAdmin() || this.isSuperAdmin() || this.isTherapist();
    })
  });
});