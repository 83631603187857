define('motorplanit/models/activity', ['exports', 'ember-data', 'ember-data/model', 'ember-data/attr'], function (exports, _emberData, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.schema = undefined;
  var schema = exports.schema = {
    name: (0, _attr.default)('string'),
    typeId: (0, _attr.default)('string'),
    avatarFileId: (0, _attr.default)('string'),
    shortDescription: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    color: (0, _attr.default)('string'),
    sensometer: (0, _attr.default)('number'),
    goals: (0, _attr.default)('string'),
    tips: (0, _attr.default)('string'),
    clinicalFocus: (0, _attr.default)('string'),
    homeAndBeyond: (0, _attr.default)('string'),
    levels: _emberData.default.hasMany('level'),
    avatar: (0, _attr.default)('file'),
    typeName: (0, _attr.default)('string'),
    modification: (0, _attr.default)('string'),
    materials: (0, _attr.default)('string'),
    isHome: (0, _attr.default)('boolean'),
    isEnterprise: (0, _attr.default)('boolean')
  };

  exports.default = _model.default.extend(schema);
});