define('motorplanit/controllers/clinics/locations/new', ['exports', 'motorplanit/mixins/locations-controller', 'motorplanit/mixins/contacts-controller'], function (exports, _locationsController, _contactsController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Controller.extend(_locationsController.default, _contactsController.default, {
    actions: {
      save: function save(changeset) {
        var clinicId = get(changeset, 'clinicId');
        var _this = this;

        var isInvalid = this.validateAllChangesets(changeset);
        if (isInvalid) return;
        return changeset.save().then(function (response) {
          var parentUrl = 'clinics/' + clinicId + '/locations/' + response.id;
          var promises = _this.saveContacts(parentUrl, get(changeset, '_contacts'));
          return Ember.RSVP.all(promises).then(function () {
            return _this.transitionToRoute('clinics.show', clinicId);
          });
        });
      }
    }
  });
});