define('motorplanit/validations/login', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    username: (0, _validators.validatePresence)(true),
    password: (0, _validators.validatePresence)(true)
  };
});