define('motorplanit/components/glossary-term-form', ['exports', 'motorplanit/validations/glossary-term', 'ember-changeset-validations', 'ember-changeset'], function (exports, _glossaryTerm, _emberChangesetValidations, _emberChangeset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get;
  exports.default = Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var model = get(this, 'model');
      this.changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_glossaryTerm.default), _glossaryTerm.default);
    }
  });
});