define('motorplanit/models/login', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.schema = undefined;
  var schema = exports.schema = {
    username: (0, _attr.default)('string'),
    password: (0, _attr.default)('string')
  };

  exports.default = _model.default.extend(schema);
});