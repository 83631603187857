define('motorplanit/helpers/activity-sensory-systems-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.activitySensorySystemsString = activitySensorySystemsString;
  function activitySensorySystemsString(params /*, hash*/) {
    return params[0].getEach('sensorySystem.name');
  }

  exports.default = Ember.Helper.helper(activitySensorySystemsString);
});