define('motorplanit/components/edit-activity-levels-form', ['exports', 'motorplanit/validations/level', 'ember-changeset-validations', 'ember-changeset'], function (exports, _level, _emberChangesetValidations, _emberChangeset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get;
  exports.default = Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.steps = [];
      var model = get(this, 'model');
      var level = get(this, 'level');
      var levelObj = void 0;

      if (Ember.isArray(model.levels) && level) levelObj = model.levels.findBy('type.id', level.id);

      if (levelObj) {
        levelObj.typeId = level.id;
        this.changesetLevel = new _emberChangeset.default(levelObj, (0, _emberChangesetValidations.default)(_level.default), _level.default);
        //Init steps array from level
        var levelSteps = levelObj.steps.mapBy("order");
        if (levelSteps.length) this.steps = levelSteps;
      } else {
        this.levelModel = this.get('store').createRecord('level');
        this.changesetLevel = new _emberChangeset.default(this.levelModel, (0, _emberChangesetValidations.default)(_level.default), _level.default);
        if (level) this.changesetLevel.set('typeId', level.id);
      }

      this.levelData = { level: this.changesetLevel, steps: [] };
      if (level) this.nestedLevels.pushObject(this.levelData);
    },
    didInsertElement: function didInsertElement() {
      $('.tabs-column li, .tab-pane').removeClass('active in');
      $('.tabs-column li:last, .tab-pane:last-child').addClass('active in');
      $('#tab-zero').removeClass('active in');

      $(".input-amount").TouchSpin({
        verticalbuttons: true,
        min: 1
      });

      $(".bootstrap-touchspin-up, .bootstrap-touchspin-down").click(function () {
        var input = $(this).closest('.bootstrap-touchspin').find('.input-amount');
        input.trigger("input");
      });
    },

    actions: {
      handleLevelTypeChange: function handleLevelTypeChange(value) {
        var level = this.activityLevelTypes.findBy('id', value);
        this.activityLevels.pushObject(level);
        this.activityLevelTypes.removeObject(level);
      },
      addStep: function addStep() {
        var lastStep = this.steps.length ? get(this.steps, 'lastObject') : 0;
        this.steps.pushObject(lastStep + 1);
      }
    }
  });
});