define('motorplanit/serializers/activity', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      var activities = Ember.A();
      activities = payload.items.map(function (item) {
        return Ember.Object.create({
          id: item.id,
          name: item.name,
          typeName: item.type.name,
          shortDescription: item.shortDescription,
          isEnterprise: item.isEnterprise,
          isHome: item.isHome
        });
      });

      payload = {
        activities: activities
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});