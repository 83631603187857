define('motorplanit/models/relation', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    userId: (0, _attr.default)('string'),
    relationshipId: (0, _attr.default)('string'),
    relationship: (0, _attr.default)(),
    user: (0, _attr.default)(),
    otherRelationship: (0, _attr.default)(),
    userParent: _emberData.default.belongsTo('user', { inverse: null })
  });
});