define('motorplanit/routes/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    permissions: Ember.inject.service(),
    beforeModel: function beforeModel() {
      var defaultRoute = this.get('permissions').renderDefaultRoute();
      this.replaceWith(defaultRoute);
    }
  });
});