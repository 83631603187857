define('motorplanit/mixins/authenticated-route', ['exports', 'motorplanit/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Mixin.create({
    permissions: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      return this._loadCurrentUser(transition);
    },
    _loadCurrentUser: function _loadCurrentUser(transition) {
      var _this = this;
      var currentRouteName = get(transition, 'targetName');
      var skipRoutes = ['change_password', 'forgot_password', 'email_sent'].includes(currentRouteName);

      // No need to get current user if is login page
      if (currentRouteName === 'login') return;

      /*return this.get('store').queryRecord('user', {me: true}).then((user) => {
        _this.set('currentUser', user);
        _this.get('permissions').set('cUser', user);
          return user
      }).catch(() => {
        if(!skipRoutes) _this.transitionTo('login');
      });*/

      return Ember.$.ajax({
        url: _environment.default.apiUrl + '/user',
        type: 'GET',
        xhrFields: { withCredentials: true },
        contentType: 'application/json;charset=utf-8',
        dataType: 'json'
      }).then(function (response) {
        if (response.item) {
          var currentUser = response.item;
          _this.set('currentUser', currentUser);
          _this.get('permissions').set('cUser', currentUser);
          return currentUser;
        } else if (!skipRoutes) {
          _this.transitionTo('login');
        }
      }).catch(function () {
        if (!skipRoutes) _this.transitionTo('login');
      });
    }
  });
});