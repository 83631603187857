define('motorplanit/mixins/contact-selector', ['exports', 'motorplanit/validations/contact', 'ember-changeset-validations', 'ember-changeset'], function (exports, _contact, _emberChangesetValidations, _emberChangeset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Mixin.create({
    addContact: function addContact(selectorId, changeset) {
      var value = $('#' + selectorId).val();
      if (value === '') return;
      var contactModel = this.get('store').createRecord('contact');
      var changesetContact = new _emberChangeset.default(contactModel, (0, _emberChangesetValidations.default)(_contact.default), _contact.default);
      var contactType = this.contactTypes.findBy('id', value);

      changesetContact.set('typeId', contactType.id);
      //For validation
      changesetContact.set('contactType', contactType.toJSON());
      get(changeset, '_contacts').pushObject(changesetContact);

      $('#' + selectorId).val('').trigger('change');
    }
  });
});