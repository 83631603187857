define("motorplanit/validators/activity-empty-field", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateActivityEmptyField;
  function validateActivityEmptyField(label) {
    return function (key, newValue, oldValue, changes) {
      var isExtendedDetails = changes.hasExtendedDetails.includes(changes.typeId);
      var inValid = isExtendedDetails && !newValue;
      return !inValid || label + " can't be blank for this Foundation option.";
    };
  }
});