define('motorplanit/mixins/locations-controller', ['exports', 'motorplanit/validations/location'], function (exports, _location) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    LocationValidations: _location.default,
    actions: {
      cancel: function cancel(changeset, clinic) {
        if (confirm('Are you sure you want to leave this page?')) {
          changeset.rollback();
          this.transitionToRoute('clinics.show', clinic.id);
        }
      },
      validateProperty: function validateProperty(changeset, property) {
        return changeset.validate(property);
      }
    }
  });
});