define('motorplanit/components/clinic-show-locations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    permissions: Ember.inject.service()
  });
});