define('motorplanit/models/user', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    userName: (0, _attr.default)('string'),
    clinicId: (0, _attr.default)('string'),
    applicationRoleId: (0, _attr.default)('string'),
    clinicRoleId: (0, _attr.default)('string'),
    dateOfBirth: (0, _attr.default)('moment'),
    genderId: (0, _attr.default)('string'),
    title: (0, _attr.default)('string'),
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    photoFileId: (0, _attr.default)('string'),
    photoFile: (0, _attr.default)('file'),
    about: (0, _attr.default)('string'),
    photo: (0, _attr.default)('file'),
    guardians: _emberData.default.hasMany('guardian', { async: true }),
    contacts: _emberData.default.hasMany('contact', { async: true }),
    clinic: (0, _attr.default)(),
    clinicRole: (0, _attr.default)(),
    gender: (0, _attr.default)(),
    applicationRole: (0, _attr.default)(),
    password: (0, _attr.default)('string'),
    relations: _emberData.default.hasMany('relation', { async: true }),
    activityProgresses: (0, _attr.default)(),
    guardianNotes: (0, _attr.default)(),
    email: (0, _attr.default)('string'),

    fullName: Ember.computed('firstName', 'lastName', 'userName', function () {
      if (this.get('firstName') || this.get('lastName')) {
        return this.get('firstName') + ' ' + this.get('lastName');
      }
      return this.get('userName');
    })
  });
});