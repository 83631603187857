define('motorplanit/controllers/forgot-password', ['exports', 'motorplanit/validations/forgot-password', 'motorplanit/config/environment'], function (exports, _forgotPassword, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Controller.extend({
    ForgotPasswordValidations: _forgotPassword.default,
    actions: {
      sendEmail: function sendEmail(changeset) {
        var email = get(changeset, 'email');
        Ember.$.ajax({
          url: _environment.default.apiUrl + '/user/forgotPassword',
          type: 'POST',
          data: JSON.stringify({ email: email }),
          xhrFields: { withCredentials: true },
          contentType: 'application/json;charset=utf-8',
          dataType: 'json'
        });
        return this.transitionToRoute('email_sent');
      },
      cancel: function cancel(changeset) {
        changeset.rollback();
        return this.transitionToRoute('login');
      },
      validateProperty: function validateProperty(changeset, property) {
        return changeset.validate(property);
      }
    }
  });
});