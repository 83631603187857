define('motorplanit/routes/clinics/locations', ['exports', 'motorplanit/mixins/clinics-routes-common', 'motorplanit/mixins/authenticated-route'], function (exports, _clinicsRoutesCommon, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRoute.default, _clinicsRoutesCommon.default, {
    model: function model(params) {
      return this.get('store').find('clinic', params.clinic_id);
    }
  });
});