define('motorplanit/routes/glossary/show', ['exports', 'motorplanit/mixins/authenticated-route'], function (exports, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRoute.default, {
    model: function model(params) {
      return this.get('store').findAll('glossaryTerm').then(function (response) {
        return response.findBy('id', params.glossary_term_id);
      });
    }
  });
});