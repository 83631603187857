define('motorplanit/routes/users/relations/new', ['exports', 'motorplanit/mixins/authenticated-route', 'motorplanit/mixins/relationships-routes-common'], function (exports, _authenticatedRoute, _relationshipsRoutesCommon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Route.extend(_authenticatedRoute.default, _relationshipsRoutesCommon.default, {
    permissions: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get('permissions.hasEditRelationshipPerms')) {
        return this.transitionTo('forbidden');
      }
    },
    model: function model() {
      var user = this.modelFor('users.relations');
      var existingRelationIds = [];
      user.get('relations').forEach(function (item) {
        existingRelationIds.push(item.get('user.id'));
      });
      var clinicId = get(user, 'clinic.id');
      var appRole = user.get('applicationRole.isPatient') ? 'applicationRole.isGuardian' : 'applicationRole.isPatient';
      var columns = user.get('applicationRole.isPatient') ? ['Patient', 'Guardian'] : ['Guardian', 'Patient'];

      return Ember.RSVP.hash({
        user: user,
        guardianRelationships: this.get('store').findAll('guardian-relationship'),
        relationship: this.get('store').createRecord('relation'),
        patientsOrGuardians: this.store.findAll('user', { reload: true }).then(function (users) {
          return users.filter(function (user) {
            return existingRelationIds.includes(user.get('id')) === false && user.get(appRole) && user.get('clinic.id') === clinicId;
          });
        }),
        columns: columns
      });
    }
  });
});