define('motorplanit/routes/clinics/index', ['exports', 'motorplanit/mixins/authenticated-route'], function (exports, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRoute.default, {
    permissions: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get('permissions').isSuperAdmin()) {
        return this.transitionTo('forbidden');
      }
    },
    model: function model() {
      return this.get('store').findAll('clinic');
    }
  });
});