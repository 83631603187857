define('motorplanit/components/application-content', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      $('body').css({ background: '' });
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce('afterRender', this, this.afterRenderEvent);
      this.$(window).on('resize', Ember.run.bind(this, this.doResize));
    },
    afterRenderEvent: function afterRenderEvent() {
      if ($('#enterpriseOrHomeFilter').length) {
        $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
          var enterpriseOrHomeValue = $('input[name="enterpriseOrHome"]:checked').val();
          var isEnterprise = data[3];
          var isHome = data[4];
          if (enterpriseOrHomeValue === 'all') {
            return true;
          }
          if (enterpriseOrHomeValue === 'enterprise' && isEnterprise === 'true') {
            return true;
          }
          if (enterpriseOrHomeValue === 'home' && isHome === 'true') {
            return true;
          }
          return false;
        });
      }

      if ($('#table').length) {
        var table = $('#table').DataTable();

        if ($('#enterpriseOrHomeFilter').length) {
          $('input[name="enterpriseOrHome"]').change(function () {
            table.draw();
          });
        }
      }

      this._fix();
    },
    doResize: function doResize() {
      this._fix();
      this.fix_sidebar();
    },
    _fix: function _fix() {
      //Get window height and the wrapper height
      var height = $(window).height() - $(".header").height();
      $(".wrapper").css("min-height", height + "px");
      var content = $(".right-side").height();

      //If the wrapper height is greater than the window
      if (content > height)
        //then set sidebar height to the wrapper
        $(".left-side, html, body").css("min-height", content + 0 + "px");else {
        //Otherwise, set the sidebar to the height of the window
        $(".left-side, html, body").css("min-height", height + 0 + "px");
      }
    },
    fix_sidebar: function fix_sidebar() {
      //Make sure the body tag has the .fixed class
      if (!$("body").hasClass("fixed")) {
        return;
      }

      //Add slimscroll
      $(".sidebar").slimscroll({
        height: $(window).height() - $(".header").height() + "px",
        color: "rgba(0,0,0,0.2)"
      });
    }
  });
});