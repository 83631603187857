define('motorplanit/validations/contact', ['exports', 'ember-changeset-validations/validators', 'motorplanit/validators/contact-data-empty-field'], function (exports, _validators, _contactDataEmptyField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    typeId: (0, _validators.validatePresence)(true),
    _data: (0, _contactDataEmptyField.default)()
  };
});