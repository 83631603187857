define('motorplanit/components/users-content', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    permissions: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      $('#table').DataTable().state.clear();
    }
  });
});