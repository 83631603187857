define('motorplanit/controllers/clinics/edit', ['exports', 'motorplanit/mixins/clinics-controller'], function (exports, _clinicsController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_clinicsController.default, {
    actions: {
      save: function save(changeset) {
        return this.createLogoAndSaveClinic(changeset);
      }
    }
  });
});