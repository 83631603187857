define('motorplanit/components/clinic-form', ['exports', 'motorplanit/validations/clinic', 'ember-changeset-validations', 'ember-changeset'], function (exports, _clinic, _emberChangesetValidations, _emberChangeset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get;
  exports.default = Component.extend({
    store: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var model = get(this, 'model');
      this.changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_clinic.default), _clinic.default);
      this.changeset.set('admins', []);
      this.changeset.set('logo', null);
      if (get(model, 'id')) this.changeset.set('parentUrl', 'clinics/' + get(model, 'id'));

      //Init admins array from clinic created.
      if (Ember.isArray(model.admins)) {
        model.admins.forEach(function (admin) {
          _this.get('changeset.admins').pushObject(admin);
        });
      }
    },
    didInsertElement: function didInsertElement() {
      var initialImage = null;
      var logoFile = get(this, 'model.logoFile');
      if (logoFile) initialImage = '<img src=' + logoFile.url + ' class=\'file-preview-image\'>';

      $(".upload-image").fileinput({
        initialPreview: [initialImage],
        showRemove: false,
        showUpload: false,
        initialCaption: 'Upload logo',
        browseLabel: "ADD LOGO"
      });
      $('#contact-type-selector').select2({
        placeholder: 'Select Contact Type',
        minimumResultsForSearch: Infinity
      });
      $('#admin-selector').select2({
        placeholder: 'Select Admin'
      });
    },

    actions: {
      handleAvatar: function handleAvatar(event) {
        this.changeset.set('logo', event.target.files[0]);
      }
    }
  });
});