define('motorplanit/adapters/location', ['exports', 'motorplanit/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _application.default.extend({
    urlForCreateRecord: function urlForCreateRecord(modelName, snapshot) {
      return this._buildContactURL(modelName, snapshot);
    },
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      return this._buildContactURL(modelName, snapshot, id);
    },
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      return this._buildContactURL(modelName, snapshot, id);
    },
    _buildContactURL: function _buildContactURL(modelName, snapshot, id) {
      var path = void 0;
      var url = [];
      var host = get(this, 'host');
      var prefix = this.urlPrefix();

      if (modelName) {
        path = this.pathForType(modelName);
        if (path) {
          url.push(path);
        }
      }

      if (snapshot.record.clinicId) {
        url.unshift('clinics/' + snapshot.record.clinicId);
      }

      if (id) {
        url.push(encodeURIComponent(id));
      }
      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');
      if (!host && url && url.charAt(0) !== '/') {
        url = '/' + url;
      }

      return url;
    }
  });
});