define('motorplanit/components/clinic-show-admins', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    actions: {
      showUser: function showUser(user) {
        this.get('router').transitionTo('users.show', user);
      }
    }
  });
});