define('motorplanit/helpers/activity-level-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.activityLevelString = activityLevelString;
  function activityLevelString(params) {
    return params[0].getEach('type.name');
  }

  exports.default = Ember.Helper.helper(activityLevelString);
});