define('motorplanit/mixins/locations-routes-common', ['exports', 'motorplanit/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    setupController: function setupController(controller, model) {
      controller.set('model', model.location);
      controller.set('clinic', model.clinic);
      controller.set('timeZones', model.timeZones);
      controller.set('contactTypes', model.contactTypes);
    },
    timeZones: function timeZones() {
      return Ember.$.ajax({
        url: _environment.default.apiUrl + '/timeZones',
        xhrFields: { withCredentials: true },
        type: 'GET',
        contentType: 'application/json;charset=utf-8',
        dataType: 'json'
      }).then(function (response) {
        return response.items;
      });
    }
  });
});