define('motorplanit/routes/users/profile', ['exports', 'motorplanit/mixins/authenticated-route', 'motorplanit/mixins/users-routes-common'], function (exports, _authenticatedRoute, _usersRoutesCommon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRoute.default, _usersRoutesCommon.default, {
    permissions: Ember.inject.service(),

    model: function model() {
      var cUser = this.get('permissions.cUser');
      var user = this.get('store').queryRecord('user', { me: true });

      return Ember.RSVP.hash({
        user: user,
        contactTypes: this.get('store').findAll('contactType'),
        fileTypes: this.get('store').findAll('fileType')
      });
    },

    setupController: function setupController(controller, model) {
      this.setModel(controller, model);
    }
  });
});