define('motorplanit/components/users-list-row', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    permissions: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var route = 'users';
      var role = this.get('user.role.name');

      if (['Super Admin', 'Clinic Admin'].includes(role)) {
        route = 'users.admin';
      }
      if (role === 'Therapist') {
        route = 'users.therapist';
      }
      if (role === 'Patient') {
        route = 'users.patient';
      }
      this.set('userRoute', route);
    }
  });
});