define('motorplanit/routes/clinics/locations/edit', ['exports', 'motorplanit/mixins/authenticated-route', 'motorplanit/mixins/locations-routes-common', 'motorplanit/mixins/clinics-routes-common'], function (exports, _authenticatedRoute, _locationsRoutesCommon, _clinicsRoutesCommon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Route.extend(_authenticatedRoute.default, _locationsRoutesCommon.default, _clinicsRoutesCommon.default, {
    permissions: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get('permissions').isSuperAdmin()) {
        return this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      var clinic = this.modelFor('clinics.locations');
      var location = get(clinic, 'locations').findBy('id', params.location_id);
      location.set('timeZoneId', get(location, 'timeZone.id'));

      return Ember.RSVP.hash({
        clinic: clinic,
        location: location,
        timeZones: this.timeZones(),
        contactTypes: this.get('store').findAll('contactType')
      });
    }
  });
});