define('motorplanit/components/top-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    permissions: Ember.inject.service(),
    fullName: Ember.computed('user.firstName', 'user.lastName', 'user.userName', function () {
      if (this.get('user.firstName') || this.get('user.lastName')) {
        return this.get('user.firstName') + ' ' + this.get('user.lastName');
      }
      return this.get('user.userName');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.user = this.get('permissions.cUser');
    },


    userImageUrl: Ember.computed('permissions.cUser.photoFile.url', function () {
      var url = this.get('user.photoFile.url');
      if (url) return url;
      return '/images/default-header-profile.jpg';
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      //Enable sidebar toggle
      this.$().on('click', "[data-toggle='offcanvas']", function (e) {
        e.preventDefault();
        //change hand icon
        $('.sidebar-toggle i').toggleClass("fa-hand-o-left");
        $('.sidebar-toggle i').toggleClass("fa-hand-o-right");

        //If window is small enough, enable sidebar push menu
        if ($(window).width() <= 992) {
          $('.row-offcanvas').toggleClass('active');
          $('.left-side').removeClass("collapse-left");
          $(".right-side").removeClass("strech");
          $('.row-offcanvas').toggleClass("relative");
        } else {
          //Else, enable content streching
          $('.left-side').toggleClass("collapse-left");
          $(".right-side").toggleClass("strech");
        }
      });
    }
  });
});