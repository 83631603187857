define('motorplanit/helpers/contact-string', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.contactString = contactString;
  function contactString(params /*, hash*/) {
    var string = void 0;
    var contacts = [];
    params[0].map(function (contact) {
      if (contact.get('contactType.' + params[1])) {
        if (params[1] === 'isAddress') {
          contacts.push('\n          ' + contact.get('addressLine1') + ',\n          ' + contact.get('addressLine2') + ',\n          ' + contact.get('state.name') + ',\n          ' + contact.get('addressCity') + ' ' + contact.get('addressPostcode') + '\n        ');
        } else {
          contacts.push(contact.get('_data'));
        }
      }
    });

    if (contacts.length) {
      string = params[2] + ': ' + contacts.join(', ');
    }

    return string;
  }

  exports.default = Ember.Helper.helper(contactString);
});