define('motorplanit/routes/users/new', ['exports', 'motorplanit/mixins/authenticated-route', 'motorplanit/mixins/users-routes-common'], function (exports, _authenticatedRoute, _usersRoutesCommon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRoute.default, _usersRoutesCommon.default, {
    permissions: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.get('permissions').isGuardian()) {
        return this.transitionTo('forbidden');
      }
    },
    model: function model() {
      var clinics = [];

      if (this.get('permissions').isSuperAdmin()) {
        clinics = this.get('store').findAll('clinic');
      }

      return Ember.RSVP.hash({
        user: this.get('store').createRecord('user'),
        genders: this.genders(),
        contactTypes: this.get('store').findAll('contactType'),
        fileTypes: this.get('store').findAll('fileType'),
        guardianRelationships: this.get('store').findAll('guardianRelationship'),
        roles: this.roles(),
        clinics: clinics,
        clinicRoles: this.get('store').findAll('clinicRole')
      });
    },

    setupController: function setupController(controller, model) {
      this.setModel(controller, model);
    }
  });
});