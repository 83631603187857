define('motorplanit/components/ember-metismenu', ['exports', 'ember-metismenu/components/ember-metismenu'], function (exports, _emberMetismenu) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberMetismenu.default;
    }
  });
});