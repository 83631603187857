define('motorplanit/components/user-form', ['exports', 'motorplanit/validations/userEdit', 'ember-changeset-validations', 'ember-changeset'], function (exports, _userEdit, _emberChangesetValidations, _emberChangeset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Component.extend({
    permissions: Ember.inject.service(),
    showClinicSelector: Ember.computed(function () {
      return this.get('permissions').isSuperAdmin() && this.get('isNew');
    }),
    showAppRoleSelector: Ember.computed(function () {
      return this.get('isEdit') || this.get('isNew');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var model = get(this, 'model');
      this.changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_userEdit.default), _userEdit.default);

      this.changeset.set('applicationRoleId', this.get('model.applicationRole.id'));
      if (this.get('model.clinic')) {
        this.changeset.set('clinicId', this.get('model.clinic.id'));
      }
      if (this.get('model.clinicRole')) {
        this.changeset.set('clinicRoleId', this.get('model.clinicRole.id'));
      }
      if (this.get('model.gender')) {
        this.changeset.set('genderId', this.get('model.gender.id'));
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var initialImage = null;
      var photoFile = get(this, 'model.photoFile');

      if (photoFile) {
        initialImage = '<img src=' + photoFile.url + ' class=\'file-preview-image\'>';
      }

      $(".upload-image").fileinput({
        initialPreview: [initialImage],
        allowedFileExtensions: ["jpg", "gif", "png", "svg"],
        showRemove: false,
        showUpload: false,
        initialCaption: 'Upload photo',
        browseLabel: "SELECT IMAGE"
      });

      $('.select-gender').select2({
        placeholder: 'Gender',
        minimumResultsForSearch: Infinity
      });

      $('.select-role').select2({
        placeholder: 'Select Role',
        minimumResultsForSearch: Infinity
      });

      $('.clinic-selector').select2({
        placeholder: 'Select Clinic',
        sorter: function sorter(data) {
          /* Sort data using lowercase comparison */
          return data.sort(function (a, b) {
            a = a.text.toLowerCase();
            b = b.text.toLowerCase();
            if (a > b) {
              return 1;
            } else if (a < b) {
              return -1;
            }
            return 0;
          });
        }
      });

      $('.clinic-role-selector').select2({
        placeholder: 'Select Clinic Role'
      });

      $('#contact-type-selector').select2({
        placeholder: 'Select Contact Type',
        minimumResultsForSearch: Infinity
      });

      $('#clinic-selector, #clinic-role-selector').css("display", this.get('model.applicationRole.isSuperAdmin') ? 'none' : 'block');
      $('#gender, #dateOfBirth').css('display', this.get('model.applicationRole.isPatient') ? 'block' : 'none');
    },

    actions: {
      handlePhoto: function handlePhoto(event) {
        this.changeset.set('photo', event.target.files[0]);
      },
      handleGenderChange: function handleGenderChange(value) {
        this.changeset.set('genderId', value);
      },
      handleClinicChange: function handleClinicChange(value) {
        this.changeset.set('clinicId', value);
      },
      handleClinicRoleChange: function handleClinicRoleChange(value) {
        this.changeset.set('clinicRoleId', value);
      },
      handleRoleChange: function handleRoleChange(value) {
        this.changeset.set('applicationRoleId', value);
        var role = this.get('roles').findBy('id', value);
        this.changeset.set('clinicId', null);
        $('#clinic-selector, #clinic-role-selector').css("display", role.name === 'Super Admin' ? 'none' : 'block');
        $('#gender, #dateOfBirth').css("display", role.name === 'Patient' ? 'block' : 'none');

        //Set clinicRoleId option selected automatically
        var roleName = role.name === "Clinic Admin" ? "Admin" : role.name;
        var clinicRole = this.clinicRoles.find(function (cRole) {
          return get(cRole, 'name') === roleName;
        });
        var clinicSelect = $('#clinic-role-selector > select');

        if (clinicRole) {
          var clinicRoleId = get(clinicRole, 'id');
          this.changeset.set('clinicRoleId', clinicRoleId);
          clinicSelect.val(clinicRoleId).trigger('change');
        } else {
          this.changeset.set('clinicRoleId', null);
          clinicSelect.val('').trigger('change');
        }
      }
    }
  });
});