define('motorplanit/routes/clinics/locations/new', ['exports', 'motorplanit/mixins/authenticated-route', 'motorplanit/mixins/locations-routes-common', 'motorplanit/mixins/clinics-routes-common'], function (exports, _authenticatedRoute, _locationsRoutesCommon, _clinicsRoutesCommon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRoute.default, _locationsRoutesCommon.default, _clinicsRoutesCommon.default, {
    permissions: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get('permissions').isSuperAdmin()) {
        return this.transitionTo('forbidden');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        clinic: this.modelFor('clinics.locations'),
        location: this.get('store').createRecord('location'),
        timeZones: this.timeZones(),
        contactTypes: this.get('store').findAll('contactType')
      });
    }
  });
});