define('motorplanit/components/device-form', ['exports', 'motorplanit/validations/device', 'ember-changeset-validations', 'ember-changeset'], function (exports, _device, _emberChangesetValidations, _emberChangeset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get;
  exports.default = Component.extend({
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.locations = [];
      var model = get(this, 'model');
      this.changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_device.default), _device.default);

      if (get(model, 'id')) {
        var clinicId = get(model, 'clinic.id');
        this.locationsList(clinicId);
        this.changeset.set('clinicId', get(model, 'clinic.id'));
        this.changeset.set('clinicLocationId', get(model, 'clinicLocation.id'));
        this.changeset.set('clinic', null);
        this.changeset.set('clinicLocation', null);
      }
    },
    didRender: function didRender() {
      $('.select-clinic-location').select2({
        placeholder: 'Select Clinic Location',
        minimumResultsForSearch: Infinity
      });

      $('.clinic-selector').select2({
        placeholder: 'Select Clinic',
        sorter: function sorter(data) {
          /* Sort data using lowercase comparison */
          return data.sort(function (a, b) {
            a = a.text.toLowerCase();
            b = b.text.toLowerCase();
            if (a > b) {
              return 1;
            } else if (a < b) {
              return -1;
            }
            return 0;
          });
        }
      });
    },
    locationsList: function locationsList(clinicId) {
      var _this = this;
      this.get('store').findRecord('clinic', clinicId).then(function (response) {
        _this.set('locations', response.get('locations'));
      });
    },

    actions: {
      handleClinicChange: function handleClinicChange(value) {
        this.changeset.set('clinicId', value);
        this.locationsList(value);
      },
      handleClinicLocationChange: function handleClinicLocationChange(value) {
        this.changeset.set('clinicLocationId', value);
      }
    }
  });
});