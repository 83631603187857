define('motorplanit/components/location-form', ['exports', 'motorplanit/validations/location', 'ember-changeset-validations', 'ember-changeset'], function (exports, _location, _emberChangesetValidations, _emberChangeset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get;
  exports.default = Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var model = get(this, 'model');
      var clinic = get(this, 'clinic');

      this.changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_location.default), _location.default);
      this.changeset.set('clinicId', get(clinic, 'id'));
    },
    didInsertElement: function didInsertElement() {
      $('.select-time-zone').select2({
        placeholder: 'Select Time Zone',
        minimumResultsForSearch: Infinity
      });
      $('#contact-type-selector').select2({
        placeholder: 'Select Contact Type',
        minimumResultsForSearch: Infinity
      });
    }
  });
});