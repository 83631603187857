define('motorplanit/mixins/relationships-routes-common', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    setupController: function setupController(controller, model) {
      controller.set('model', model.relationship);
      controller.set('user', model.user);
      controller.set('guardianRelationships', model.guardianRelationships);
      controller.set('patientsOrGuardians', model.patientsOrGuardians);
      controller.set('columns', model.columns);
    }
  });
});