define('motorplanit/controllers/devices/edit', ['exports', 'motorplanit/mixins/devices-controller'], function (exports, _devicesController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_devicesController.default, {
    actions: {
      save: function save(changeset) {
        this.saveDeviceAndSetPassword(changeset);
      }
    }
  });
});