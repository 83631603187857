define('motorplanit/components/clinic-contact-address-fields', ['exports', 'motorplanit/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      this.countries = [];
      this.states = [];
      this.countriesList();
    },
    didRender: function didRender() {
      $('.country-selector').select2({ placeholder: 'Select Country' });
    },
    countriesList: function countriesList() {
      var _this = this;
      return this.get('store').findAll('country').then(function (response) {
        _this.set('countries', response);
      });
    },
    statesList: function statesList(countryId) {
      var _this = this;

      Ember.$.get({
        url: _environment.default.apiUrl + '/countries/' + countryId + '/states',
        xhrFields: { withCredentials: true }
      }).then(function (response) {
        _this.set('states', response.items);
      });
    },

    actions: {
      handleCountryChange: function handleCountryChange(value) {
        this.changesetContact.set('addressCountryId', value);
      },
      handleStateChange: function handleStateChange(value) {
        this.changesetContact.set('addressCountryStateId', value);
      },
      handleCityChange: function handleCityChange(value) {
        this.changesetContact.set('addressCountryCityId', value);
      }
    }
  });
});