define('motorplanit/mixins/glossary-terms-controller', ['exports', 'motorplanit/validations/glossary-term', 'motorplanit/mixins/global-error-handler'], function (exports, _glossaryTerm, _globalErrorHandler) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_globalErrorHandler.default, {
    GlossaryTermValidations: _glossaryTerm.default,
    actions: {
      validateProperty: function validateProperty(changeset, property) {
        return changeset.validate(property);
      },
      cancel: function cancel(changeset) {
        if (confirm('Are you sure you want to leave this page?')) {
          changeset.rollback();
          this.transitionToRoute('glossary');
        }
      }
    }
  });
});