define("motorplanit/components/edit-activity-level-steps-form", ["exports", "motorplanit/validations/level-step", "ember-changeset-validations", "ember-changeset", "fuse"], function (exports, _levelStep, _emberChangesetValidations, _emberChangeset, _fuse) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var step = get(this, "step");

      if (Ember.isArray(this.levelData.level._content.steps)) {
        this.stepObj = this.levelData.level._content.steps.findBy("order", step);
      }

      if (this.stepObj) {
        this.changesetLevelStep = new _emberChangeset.default(this.stepObj, (0, _emberChangesetValidations.default)(_levelStep.default), _levelStep.default);
      } else {
        var stepModel = this.get("store").createRecord("level-step", {
          order: step
        });
        this.changesetLevelStep = new _emberChangeset.default(stepModel, (0, _emberChangesetValidations.default)(_levelStep.default), _levelStep.default);
      }
      this.levelData.steps.pushObject(this.changesetLevelStep);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var step = get(this, "step");
      var defaultOptions = {
        showRemove: false,
        showUpload: false,
        showCaption: false,
        browseLabel: "UPLOAD IMAGE"
      };
      var videoOptions = {
        initialCaption: "Upload video",
        showCaption: true,
        browseLabel: "ADD VIDEO"
      };
      var options = {};

      if (this.level) {
        var inputId = "#" + this.level.id + "-step-" + step;
        var stepImageInputId = $(inputId + "-image");
        var stepVideoInputId = $(inputId + "-video");

        if (this.stepObj && this.stepObj.imageFile) {
          var initialStepImage = "<img src='" + this.stepObj.imageFile.url + "' class='file-preview-image'>";
          options = {
            initialPreview: ["<img src='" + this.stepObj.imageFile.url + "' class='file-preview-image'>"],
            initialPreviewShowDelete: false,
            initialPreviewAsData: true,
            initialPreviewConfig: [{ type: "image" }],
            showCaption: false,
            showClose: false
          };
        }

        stepImageInputId.fileinput(Ember.assign({}, defaultOptions, options));
        options = {};

        if (this.stepObj && this.stepObj.videoFile) {
          options = {
            initialPreview: ["<video width='213px' height='160px\" controls=''>\n             <source src=" + this.stepObj.videoFile.url + " type=\"video/mp4\">\n             <div class=\"file-preview-other\">\n              <i class=\"glyphicon glyphicon-file\"></i>\n             </div>\n            </video>"],
            showClose: false
          };
        }

        stepVideoInputId.fileinput(Ember.assign({}, defaultOptions, videoOptions, options));
      } else {
        var imageInputId = "#-step-" + step;
        $(imageInputId + "-image").fileinput(defaultOptions);
        $(imageInputId + "-video").fileinput(Ember.assign({}, defaultOptions, videoOptions));
      }
    },


    actions: {
      initGallery: function initGallery(item) {
        $("#" + this.elementId + " .step-form .btn-img-open").parents(".avatar-image").find(".pswp").show();
        console.log($("#" + this.elementId + " .step-form .btn-img-open"));
        this.get("myGallery").init();
        this.get("myGallery").goTo(item);
      },
      handleImageChange: function handleImageChange(imageId) {
        this.changesetLevelStep.set("imageFileId", imageId);
        this.changesetLevelStep.set("image", null);
      },
      handleUpdate: function handleUpdate(event) {
        var file = event.target.files[0];
        var prop = event.target.className === "step-image" ? "image" : "video";
        this.changesetLevelStep.set(prop, file);
      },
      deleteStep: function deleteStep(step) {
        var confirmed = window.confirm("Are you sure you want to delete?");

        if (confirmed) {
          this.steps.removeObject(step);
          var changesetLevelStep = this.levelData.steps.find(function (_step) {
            return get(_step, "order") === step;
          });

          this.levelData.steps.removeObject(changesetLevelStep);
        }
      },
      openAvatarPreview: function openAvatarPreview() {
        $("#" + this.elementId + " .preview-avatar").show();
        $("#" + this.elementId + " .form-control.preview-input").val("");
        this.actions.searchPreviewItem("");
      },
      closeAvatarPreview: function closeAvatarPreview() {
        $("#" + this.elementId + " .preview-avatar").hide();
      },
      searchPreviewItem: function searchPreviewItem(search) {
        var searchTag = "#" + this.elementId + " .step-form .preview-element";

        if (search === "") {
          $(".preview-element").show();
          return;
        }

        $(searchTag).hide();

        var options = {
          includeMatches: true,
          keys: ["word"],
          shouldSort: true,
          threshold: 0.1
        };
        var searchArr = $(searchTag).toArray().map(function (x) {
          return { word: $(x).data("name").toLowerCase(), item: x };
        });
        var fuse = new _fuse.default(searchArr, options);
        var result = fuse.search(search);
        result.forEach(function (x) {
          return $(x.item.item).show();
        });
      }
    }
  });
});