define('motorplanit/adapters/user', ['exports', 'motorplanit/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = _application.default.extend({
    permissions: Ember.inject.service(),

    urlForQueryRecord: function urlForQueryRecord(query) {
      if (query.me) {
        delete query.me;
        return this._buildCurrentUserURL();
      }

      return this._super.apply(this, arguments);
    },
    _buildCurrentUserURL: function _buildCurrentUserURL() {
      var path = void 0;
      var url = [];
      var host = get(this, 'host');
      var prefix = this.urlPrefix();

      url.push('user');
      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');
      if (!host && url && url.charAt(0) !== '/') {
        url = '/' + url;
      }

      return url;
    },
    generatedDetailedMessage: function generatedDetailedMessage(status, headers, payload) {
      return payload.error.message;
    }
  });
});