define('motorplanit/serializers/device', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      clinic: { embedded: 'always' },
      clinicLocation: { embedded: 'always' }
    },

    normalizeFindAllResponse: function normalizeFindAllResponse(store, primaryModelClass, payload, id, requestType) {
      payload = {
        devices: payload.items
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    },
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      payload.item.clinicLocation = payload.item.clinicLocationId;
      payload.item.clinic = payload.item.clinicId;
      payload = {
        device: payload.item
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    serializeIntoHash: function serializeIntoHash(hash, type, record, options) {
      Ember.merge(hash, this.serialize(record, options));
    },
    normalizeSaveResponse: function normalizeSaveResponse(store, primaryModelClass, payload, id, requestType) {
      payload = {
        device: payload.item
      };

      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});