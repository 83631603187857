define('motorplanit/controllers/devices/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    permissions: Ember.inject.service(),
    actions: {
      delete: function _delete(model) {
        var _this = this;

        var confirmed = window.confirm('Are you sure you want to delete this Device?');

        if (confirmed) {
          this.get('store').findAll('device', { backgroundReload: false }).then(function (response) {
            var device = response.findBy('id', model.id);
            device.destroyRecord().then(function () {
              return _this.transitionToRoute('devices');
            });
          });
        }
      }
    }
  });
});