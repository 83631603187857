define('motorplanit/models/activity-type', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: (0, _attr.default)('string'),
    color: (0, _attr.default)('string'),
    order: (0, _attr.default)('number'),
    isSensory: (0, _attr.default)('boolean'),
    isSocialSkillChallenge: (0, _attr.default)('boolean'),
    isAvatarUsed: (0, _attr.default)('boolean'),
    hasExtendedDetails: (0, _attr.default)('boolean'),
    completionType: (0, _attr.default)(),
    requiredExperienceCountForCompletion: (0, _attr.default)('number'),
    hasLevels: (0, _attr.default)('boolean'),
    hasColor: (0, _attr.default)('boolean')
  });
});