define('motorplanit/components/patient-info', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var father = this.get('model.guardians').findBy('relationship.name', 'Father');
      if (father) {
        this.set('fatherName', father.firstName + ' ' + father.lastName);
      }
    }
  });
});