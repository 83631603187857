define('motorplanit/validations/activity', ['exports', 'ember-changeset-validations/validators', 'motorplanit/validators/activity-empty-field', 'ember-changeset-conditional-validations/validators/sometimes'], function (exports, _validators, _activityEmptyField, _sometimes) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: (0, _validators.validatePresence)(true),
    typeId: (0, _validators.validatePresence)({ presence: true, message: "Foundation can't be blank. Please select an option." }),
    sensometer: (0, _activityEmptyField.default)('Sensometer'),
    // isHome: validateActivityEmptyField('isHome'),
    // isEnterprise: validateActivityEmptyField('isEnterprise'),
    goals: (0, _activityEmptyField.default)('Goals'),
    tips: (0, _activityEmptyField.default)('Tips'),
    // avatarFileId: validateActivityEmptyField('Avater File Id'),
    clinicalFocus: (0, _activityEmptyField.default)('Clincal Focus'),
    homeAndBeyond: (0, _activityEmptyField.default)('Home & Beyond'),
    modifications: (0, _sometimes.default)((0, _activityEmptyField.default)('Modifications'), function () {
      return this.get('isHome');
    }),
    materials: (0, _sometimes.default)((0, _activityEmptyField.default)('Materials'), function () {
      return this.get('isHome');
    })
  };
});