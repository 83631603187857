define('motorplanit/components/contacts-form-panel', ['exports', 'motorplanit/validations/contact', 'ember-changeset-validations', 'ember-changeset', 'motorplanit/mixins/contact-selector'], function (exports, _contact, _emberChangesetValidations, _emberChangeset, _contactSelector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Component.extend(_contactSelector.default, {
    store: Ember.inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var model = get(this, 'model');
      this.changeset.set('_contacts', []);
      //Find contacts for object already created.
      if (typeof get(model, 'contacts') !== 'undefined') {
        get(model, 'contacts').forEach(function (modelContact) {
          if (get(modelContact, 'contactType.isAddress')) {
            modelContact.addressCountryId = get(modelContact, 'addressCountry.id');
            modelContact.state = get(modelContact, 'addressCountryState');
            modelContact.addressCity = get(modelContact, 'addressCountryCity');
          }

          var changesetContact = new _emberChangeset.default(modelContact, (0, _emberChangesetValidations.default)(_contact.default), _contact.default);
          changesetContact.set('_data', get(modelContact, '_data'));
          changesetContact.set('typeId', get(modelContact, 'contactType.id'));
          _this.get('changeset._contacts').pushObject(changesetContact);
        });
      }
    },
    didRender: function didRender() {
      if (this.get('changeset._contacts').length === 0) $('.panel-contacts').hide();
    },

    actions: {
      handleContactTypeChange: function handleContactTypeChange() {
        this.addContact('contact-type-selector', this.changeset);
        $('.panel-contacts').show();
      }
    }
  });
});