define('motorplanit/components/activity-show-levels', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component,
	    get = Ember.get;
	exports.default = Component.extend({
		didInsertElement: function didInsertElement() {
			$('.tabs-column li, .tab-pane').removeClass('active in');
			$('.tabs-column li:first, .tab-pane:first-child').addClass('active in');
		}
	});
});