define('motorplanit/routes/activities/activity', ['exports', 'motorplanit/config/environment', 'motorplanit/mixins/authenticated-route'], function (exports, _environment, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRoute.default, {
    permissions: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get('permissions').canViewActivities()) {
        return this.transitionTo('forbidden');
      }
    },
    model: function model(params) {
      return Ember.$.ajax({
        url: _environment.default.apiUrl + '/activities/' + params.activity_id,
        xhrFields: { withCredentials: true },
        type: 'GET',
        contentType: 'application/json;charset=utf-8',
        dataType: 'json'
      }).then(function (response) {
        return response.item;
      });
    },

    actions: {
      delete: function _delete(model) {
        var confirmed = window.confirm('Are you sure you want to delete this Activity?');
        var _this = this;
        if (confirmed) {
          Ember.$.ajax({
            url: _environment.default.apiUrl + '/activities/' + model.id,
            xhrFields: { withCredentials: true },
            type: 'DELETE',
            contentType: 'application/json;charset=utf-8'
          }).then(function () {
            _this.transitionTo('activities');
          }).catch(function () {
            alert('There was an error trying to delete this activity.');
          });
        }
      },
      assignActivityto: function assignActivityto(model) {
        var emptyUDID = '00000000-0000-0000-0000-000000000000';

        var formData = {
          'dateInClinicLocationTimezone': "2020-10-22T13:41:30.196Z",
          'clinicLocationId': emptyUDID,
          'patientId': emptyUDID,
          'courseDefinitionId': emptyUDID
        };

        var dataString = JSON.stringify(formData);
        Ember.$.ajax({
          url: _environment.default.apiUrl + ('/usercourses/dailyactivities/addactivity/c3231f44-bf15-4575-a5c8-7f7f6a735304/' + model.id),
          data: dataString,
          xhrFields: { withCredentials: true },
          type: 'POST',
          contentType: 'application/json;charset=utf-8'
        }).then(function () {
          alert("Activity assigned to Sam: c3231f44-bf15-4575-a5c8-7f7f6a735304");
        });
      },
      duplicate: function duplicate(model) {
        var activityId = model.id;
        var _this = this;
        Ember.$.ajax({
          url: _environment.default.apiUrl + '/activities/copy/' + activityId,
          xhrFields: { withCredentials: true },
          type: 'POST',
          contentType: false,
          processData: false
        }).then(function (response) {
          alert("Activity copied successfully");
          var router = _this.get('router');
          router.transitionTo('activities.edit', response.item);
        });
      }
    }
  });
});