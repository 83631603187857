define('motorplanit/routes/devices/new', ['exports', 'motorplanit/mixins/authenticated-route'], function (exports, _authenticatedRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRoute.default, {
    permissions: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (!this.get('permissions.hasEditDevicesPerms')) {
        return this.transitionTo('forbidden');
      }
    },
    model: function model() {
      return Ember.RSVP.hash({
        device: this.get('store').createRecord('device'),
        clinics: this.get('store').findAll('clinic')
      });
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model.device);
      controller.set('clinics', model.clinics);
    }

  });
});