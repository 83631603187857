define('motorplanit/components/patient-notes', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        didRender: function didRender() {
            if ($('#notes-table').length) {
                $('#notes-table').DataTable();
            }
        }
    });
});